import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './PropertySavedContainer3.css';
import propertySavedContainer3Img1 from '../../Assets/Images/PropertySaved/propertysavedcontainer3-img-1.png';
import bed from '../../Assets/Images/PropertySaved/propertysavedcontainer3-icon-bed.png';
import bath from '../../Assets/Images/PropertySaved/propertysavedcontainer4-icon-bath.png';
import deleteIcon from '../../Assets/Images/PropertySaved/deleteIcon.png';

function PropertySavedContainer3() {
    return (
        <div className="propertysavedcontainer3">
            <div className="container-fluid propertysavedcontainer3-container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-sm-12">

                        <div className="row justify-content-between">
                            <div className="col-lg-6 col-md-12">
                                <div className="row propertysaved-div-main">
                                    <div className="col-lg-6 col-md-6 p-0">
                                        <img src={propertySavedContainer3Img1} className="w-100 propertysaved-img-main" alt="" />
                                    </div>
                                    <div className="col-lg-6 col-md-6">

                                        <div className="row w-100 mt-2">
                                            <div className="d-flex justify-content-between align-items-center w-100">
                                                <label htmlFor="" className="text-secondary fs-5">New</label>
                                                <img src={deleteIcon} alt="" className="propertysavedcontainer3-del-icon" />
                                            </div>
                                        </div>

                                        <div className="row w-100 mt-2">
                                            <label htmlFor=""><h5>4 bed detached house for sale</h5></label>
                                        </div>

                                        <div className="row w-100 mt-2">

                                            <div className="col-12">
                                                <div className="row align-items-center propertysaved-custom-mobile">
                                                    <div className="col-1">
                                                        <img src={bed} className="div-icons mb-1" alt="" />
                                                    </div>
                                                    <div className="col-9">
                                                        <label htmlFor="" className="ms-1">4 bedrooms</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 mt-2">
                                                <div className="row align-items-center propertysaved-custom-mobile">
                                                    <div className="col-1">
                                                        <img src={bath} className="div-icons mb-1" alt="" />
                                                    </div>
                                                    <div className="col-auto">
                                                        <label htmlFor="" className="ms-1">2 bathrooms</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 mt-2">
                                                <div className="row align-items-center propertysaved-custom-mobile">
                                                    <div className="col-12">
                                                        <label htmlFor="" className="test">5000 ft²</label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row w-100 mt-4">
                                            <label htmlFor=""><h5>£1,084,942</h5></label>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12">
                                <div className="row propertysaved-div-main">
                                    <div className="col-lg-6 col-md-6 p-0">
                                        <img src={propertySavedContainer3Img1} className="w-100 propertysaved-img-main" alt="" />
                                    </div>
                                    <div className="col-lg-6 col-md-6">

                                        <div className="row w-100 mt-2">
                                            <div className="d-flex justify-content-between align-items-center w-100">
                                                <label htmlFor="" className="text-secondary fs-5">New</label>
                                                <img src={deleteIcon} alt="" className="propertysavedcontainer3-del-icon" />
                                            </div>
                                        </div>

                                        <div className="row w-100 mt-2">
                                            <label htmlFor=""><h5>4 bed detached house for sale</h5></label>
                                        </div>

                                        <div className="row w-100 mt-2">

                                            <div className="col-12">
                                                <div className="row align-items-center propertysaved-custom-mobile">
                                                    <div className="col-1">
                                                        <img src={bed} className="div-icons mb-1" alt="" />
                                                    </div>
                                                    <div className="col-9">
                                                        <label htmlFor="" className="ms-1">4 bedrooms</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 mt-2">
                                                <div className="row align-items-center propertysaved-custom-mobile">
                                                    <div className="col-1">
                                                        <img src={bath} className="div-icons mb-1" alt="" />
                                                    </div>
                                                    <div className="col-auto">
                                                        <label htmlFor="" className="ms-1">2 bathrooms</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 mt-2">
                                                <div className="row align-items-center propertysaved-custom-mobile">
                                                    <div className="col-12">
                                                        <label htmlFor="" className="test">5000 ft²</label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row w-100 mt-4">
                                            <label htmlFor=""><h5>£1,084,942</h5></label>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row justify-content-between mt-4">
                            <div className="col-lg-6 col-md-12">
                                <div className="row propertysaved-div-main">
                                    <div className="col-lg-6 col-md-6 p-0">
                                        <img src={propertySavedContainer3Img1} className="w-100 propertysaved-img-main" alt="" />
                                    </div>
                                    <div className="col-lg-6 col-md-6">

                                        <div className="row w-100 mt-2">
                                            <div className="d-flex justify-content-between align-items-center w-100">
                                                <label htmlFor="" className="text-secondary fs-5">New</label>
                                                <img src={deleteIcon} alt="" className="propertysavedcontainer3-del-icon" />
                                            </div>
                                        </div>

                                        <div className="row w-100 mt-2">
                                            <label htmlFor=""><h5>4 bed detached house for sale</h5></label>
                                        </div>

                                        <div className="row w-100 mt-2">

                                            <div className="col-12">
                                                <div className="row align-items-center propertysaved-custom-mobile">
                                                    <div className="col-1">
                                                        <img src={bed} className="div-icons mb-1" alt="" />
                                                    </div>
                                                    <div className="col-9">
                                                        <label htmlFor="" className="ms-1">4 bedrooms</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 mt-2">
                                                <div className="row align-items-center propertysaved-custom-mobile">
                                                    <div className="col-1">
                                                        <img src={bath} className="div-icons mb-1" alt="" />
                                                    </div>
                                                    <div className="col-auto">
                                                        <label htmlFor="" className="ms-1">2 bathrooms</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 mt-2">
                                                <div className="row align-items-center propertysaved-custom-mobile">
                                                    <div className="col-12">
                                                        <label htmlFor="" className="test">5000 ft²</label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row w-100 mt-4">
                                            <label htmlFor=""><h5>£1,084,942</h5></label>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12">
                                <div className="row propertysaved-div-main">
                                    <div className="col-lg-6 col-md-6 p-0">
                                        <img src={propertySavedContainer3Img1} className="w-100 propertysaved-img-main" alt="" />
                                    </div>
                                    <div className="col-lg-6 col-md-6">

                                        <div className="row w-100 mt-2">
                                            <div className="d-flex justify-content-between align-items-center w-100">
                                                <label htmlFor="" className="text-secondary fs-5">New</label>
                                                <img src={deleteIcon} alt="" className="propertysavedcontainer3-del-icon" />
                                            </div>
                                        </div>

                                        <div className="row w-100 mt-2">
                                            <label htmlFor=""><h5>4 bed detached house for sale</h5></label>
                                        </div>

                                        <div className="row w-100 mt-2">

                                            <div className="col-12">
                                                <div className="row align-items-center propertysaved-custom-mobile">
                                                    <div className="col-1">
                                                        <img src={bed} className="div-icons mb-1" alt="" />
                                                    </div>
                                                    <div className="col-9">
                                                        <label htmlFor="" className="ms-1">4 bedrooms</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 mt-2">
                                                <div className="row align-items-center propertysaved-custom-mobile">
                                                    <div className="col-1">
                                                        <img src={bath} className="div-icons mb-1" alt="" />
                                                    </div>
                                                    <div className="col-auto">
                                                        <label htmlFor="" className="ms-1">2 bathrooms</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 mt-2">
                                                <div className="row align-items-center propertysaved-custom-mobile">
                                                    <div className="col-12">
                                                        <label htmlFor="" className="test">5000 ft²</label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row w-100 mt-4">
                                            <label htmlFor=""><h5>£1,084,942</h5></label>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-5 mb-5 fw-medium">
                            <nav aria-label="Page navigation" className="d-flex justify-content-center">
                                <ul className="pagination text-dark">
                                    <li className="page-item"><a className="page-link" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" stroke="currentColor" className="bi bi-chevron-left me-1 mb-1" viewBox="0 0 16 16">
                                        <linearGradient id="PaginationGradient" x1="0" y1="0" x2="1" y2="0">
                                            <stop offset="0%" stopColor="rgb(162, 139, 250)" />
                                            <stop offset="52.08%" stopColor="rgb(206, 112, 193)" />
                                            <stop offset="100%" stopColor="rgb(213, 107, 112)" />
                                        </linearGradient>
                                        <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0" />
                                    </svg><span className="text-dark">Prev</span></a></li>
                                    <li className="page-item"><a className="page-link text-dark active" href="#">1</a></li>
                                    <li className="page-item"><a className="page-link text-dark" href="#">2</a></li>
                                    <li className="page-item"><a className="page-link text-dark" href="#">3</a></li>
                                    <li className="page-item"><a className="page-link text-dark" href="#">4</a></li>
                                    <li className="page-item"><a className="page-link text-dark" href="#">5</a></li>
                                    <li className="page-item"><a className="page-link text-dark" href="#">...</a></li>
                                    <li className="page-item"><a className="page-link text-dark" href="#">15</a></li>
                                    <li className="page-item"><a className="page-link text-dark" href="#"><span className="me-2">Next</span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 0 .708L10.293 8l-5.647 5.646a.5.5 0 1 1-.708-.708l6-6a.5.5 0 0 1 0-.708l-6-6a.5.5 0 0 1 .708 0" />
                                    </svg></a></li>
                                </ul>
                            </nav>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default PropertySavedContainer3;