import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import PropertySavedContainer2 from "../Components/PropertySavedContainer2/PropertySavedContainer2";
import PropertySavedContainer3 from "../Components/PropertySavedContainer3/PropertySavedContainer3";

const PropertySaved = () => {
    return (
        <div className="propertysaved">
            <div className="container-fluid">

                <div className="row">

                    <div className="col-lg-12">
                        <div className="container-box">
                            < Header />
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="container-box">
                            <PropertySavedContainer2 />
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="container-box">
                            <PropertySavedContainer3/>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="container-box">
                            < Footer />
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default PropertySaved;