import React from 'react';
import PropTypes from 'prop-types';
import './NewsItem.css';

const NewsItem = ({ image, title, description, daysAgo, onReadMore }) => {
  return (
    <div className="col-sm-md-12 col-lg-4 newsItem-row2-div">
      <div className="row">
        <img src={image} className="newsItem-img" alt="" />
      </div>
      <div className="row w-100">
        <p className="newsItem-p-2">{title}</p>
      </div>
      <div className="row">
        <p className="newsItem-p-3">{description}</p>
      </div>
      <div className="row d-flex w-100 justify-content-between">
        <div className="col-6">
          <p className="newsItem-p-4">
            <span className="newsItem-gradient" onClick={onReadMore}>Read more</span>
          </p>
        </div>
        <div className="col-6 text-end">
          <p className="newsItem-p-5">
            <span>{daysAgo} days ago</span>
          </p>
        </div>
      </div>
    </div>
  );
};

NewsItem.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  daysAgo: PropTypes.number.isRequired,
  onReadMore: PropTypes.func.isRequired
};

export default NewsItem;
