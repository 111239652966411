import React from "react";
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import Breadcrumbs from '../Components/Breadcrumbs/Breadcrumbs';
import HeroPage from "../Components/HeroPage/HeroPage";
import NewsItem from "../Components/NewsItem/NewsItem";
import Layout from "../Components/Layout/Layout";

import propertyNewsHeroImg from '../Assets/Images/PropertyNews/propertynewscontainer3-hero.jpg';
import propertyNewsContainer4Img1 from '../Assets/Images/PropertyNews/propertynewscontainer4-img-1.jpg';
import propertyNewsContainer4Img2 from '../Assets/Images/PropertyNews/propertynewscontainer4-img-2.jpg';
import propertyNewsContainer4Img3 from '../Assets/Images/PropertyNews/propertynewscontainer4-img-3.jpg';

const PropertyNews = () => {
  const breadcrumbs = [
    { href: "/", label: "Home" },
    { href: "/property-news", label: "Property News" }
  ];

  const navigate = useNavigate();

  const handleTextClick = () => {
    navigate("/property-news-detail");
  };

  // Mock data for news items (API later)
  const newsItems = [
    { image: propertyNewsContainer4Img1, title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.", description: "Nunc vulputate libero et velit interdum, ac aliquet odio mattis.", daysAgo: 4 },
    { image: propertyNewsContainer4Img2, title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.", description: "Nunc vulputate libero et velit interdum, ac aliquet odio mattis.", daysAgo: 4 },
    { image: propertyNewsContainer4Img3, title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.", description: "Nunc vulputate libero et velit interdum, ac aliquet odio mattis.", daysAgo: 4 },
    { image: propertyNewsContainer4Img1, title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.", description: "Nunc vulputate libero et velit interdum, ac aliquet odio mattis.", daysAgo: 4 },
    { image: propertyNewsContainer4Img2, title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.", description: "Nunc vulputate libero et velit interdum, ac aliquet odio mattis.", daysAgo: 4 },
    { image: propertyNewsContainer4Img3, title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.", description: "Nunc vulputate libero et velit interdum, ac aliquet odio mattis.", daysAgo: 4 },
    { image: propertyNewsContainer4Img1, title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.", description: "Nunc vulputate libero et velit interdum, ac aliquet odio mattis.", daysAgo: 4 },
    { image: propertyNewsContainer4Img2, title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.", description: "Nunc vulputate libero et velit interdum, ac aliquet odio mattis.", daysAgo: 4 },
    { image: propertyNewsContainer4Img3, title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.", description: "Nunc vulputate libero et velit interdum, ac aliquet odio mattis.", daysAgo: 4 },
    { image: propertyNewsContainer4Img1, title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.", description: "Nunc vulputate libero et velit interdum, ac aliquet odio mattis.", daysAgo: 4 },
    { image: propertyNewsContainer4Img2, title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.", description: "Nunc vulputate libero et velit interdum, ac aliquet odio mattis.", daysAgo: 4 },
    { image: propertyNewsContainer4Img3, title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.", description: "Nunc vulputate libero et velit interdum, ac aliquet odio mattis.", daysAgo: 4 }
  ];

  return (
    <Layout>
      <div className="container-fluid">
        <div className="row">

          <div className="col-lg-12 container-box">
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </div>

          <div className="col-lg-12 container-box">
            <HeroPage
              title="Property News"
              subtitle="The latest property news, guides, and reviews from our team!"
              backgroundImage={propertyNewsHeroImg}
            />
          </div>

          <div className="col-lg-12 container-box">
            <div className="propertynewscontainer4">
              <div className="container-fluid propertynewscontainer4-container">
                <div className="row justify-content-center">
                  <div className="col-8 mt-5">
                    {Array.from({ length: Math.ceil(newsItems.length / 3) }).map((_, rowIndex) => (
                      <div className="row justify-content-between mt-4" key={rowIndex}>
                        {newsItems.slice(rowIndex * 3, rowIndex * 3 + 3).map((item, index) => (
                          <NewsItem
                            key={index}
                            image={item.image}
                            title={item.title}
                            description={item.description}
                            daysAgo={item.daysAgo}
                            onReadMore={handleTextClick}
                          />
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </Layout>
  )
}

export default PropertyNews;