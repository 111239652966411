import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from '../Components/Layout/Layout';
import AgentCard from "../Components/AgentCard/AgentCard";

const mockAgents = [
  { name: 'Alistair Redhouse Estate', address: '234 Botley Road Oxford OX2 0HP', propertiesForSale: 60, propertiesForRent: 40, brandLogo: require('../Assets/Images/EstateAgents/estateagentcontainer3-brand.png') },
  { name: 'Castle Estate Agents', address: '123 High Street, London W1D 4AB', propertiesForSale: 100, propertiesForRent: 80, brandLogo: require('../Assets/Images/EstateAgents/estateagentcontainer3-brand.png') },
  { name: 'Kings Estate Agents', address: '456 Elm Street, Birmingham B2 4BG', propertiesForSale: 75, propertiesForRent: 55, brandLogo: require('../Assets/Images/EstateAgents/estateagentcontainer3-brand.png') },
  { name: 'Queens Estate Agents', address: '789 Maple Avenue, Manchester M1 2JL', propertiesForSale: 50, propertiesForRent: 30, brandLogo: require('../Assets/Images/EstateAgents/estateagentcontainer3-brand.png') },
  { name: 'Premier Properties', address: '101 Birch Road, Leeds LS1 5DL', propertiesForSale: 90, propertiesForRent: 70, brandLogo: require('../Assets/Images/EstateAgents/estateagentcontainer3-brand.png') },
  { name: 'Modern Estates', address: '202 Pine Street, Liverpool L1 4ED', propertiesForSale: 80, propertiesForRent: 60, brandLogo: require('../Assets/Images/EstateAgents/estateagentcontainer3-brand.png') },
  { name: 'Cityscape Realtors', address: '303 Cedar Lane, Glasgow G1 1ZP', propertiesForSale: 85, propertiesForRent: 65, brandLogo: require('../Assets/Images/EstateAgents/estateagentcontainer3-brand.png') },
  { name: 'Urban Living', address: '404 Walnut Drive, Bristol BS1 6GH', propertiesForSale: 95, propertiesForRent: 75, brandLogo: require('../Assets/Images/EstateAgents/estateagentcontainer3-brand.png') },
  { name: 'Heritage Homes', address: '505 Oak Avenue, Edinburgh EH1 3AD', propertiesForSale: 70, propertiesForRent: 50, brandLogo: require('../Assets/Images/EstateAgents/estateagentcontainer3-brand.png') },
  { name: 'Metropolitan Estates', address: '606 Cherry Boulevard, Cardiff CF1 2KL', propertiesForSale: 65, propertiesForRent: 45, brandLogo: require('../Assets/Images/EstateAgents/estateagentcontainer3-brand.png') },
  { name: 'Luxury Listings', address: '707 Ash Crescent, Sheffield S1 1TT', propertiesForSale: 110, propertiesForRent: 90, brandLogo: require('../Assets/Images/EstateAgents/estateagentcontainer3-brand.png') },
  { name: 'Suburban Realty', address: '808 Willow Road, Newcastle NE1 3RD', propertiesForSale: 55, propertiesForRent: 35, brandLogo: require('../Assets/Images/EstateAgents/estateagentcontainer3-brand.png') },
  { name: 'Suburban Realty', address: '808 Willow Road, Newcastle NE1 3RD', propertiesForSale: 55, propertiesForRent: 35, brandLogo: require('../Assets/Images/EstateAgents/estateagentcontainer3-brand.png') },
  { name: 'Suburban Realty', address: '808 Willow Road, Newcastle NE1 3RD', propertiesForSale: 55, propertiesForRent: 35, brandLogo: require('../Assets/Images/EstateAgents/estateagentcontainer3-brand.png') },
  { name: 'Suburban Realty', address: '808 Willow Road, Newcastle NE1 3RD', propertiesForSale: 55, propertiesForRent: 35, brandLogo: require('../Assets/Images/EstateAgents/estateagentcontainer3-brand.png') },
];

const agentsPerPage = 12; // Number of agents to display per page

function EstateAgents() {
  const [currentPage, setCurrentPage] = useState(1);
  const [location, setLocation] = useState('');
  const [agentName, setAgentName] = useState('');
  const [agentType, setAgentType] = useState('');

  // Calculate the index range of agents to display on the current page
  const indexOfLastAgent = currentPage * agentsPerPage;
  const indexOfFirstAgent = indexOfLastAgent - agentsPerPage;

  // Filter agents based on search criteria
  const filteredAgents = mockAgents.filter(agent => {
    return (
      (!location || agent.address.toLowerCase().includes(location.toLowerCase())) &&
      (!agentName || agent.name.toLowerCase().includes(agentName.toLowerCase())) &&
      (!agentType || (agentType === 'sales' && agent.propertiesForSale > 0) || (agentType === 'lettings' && agent.propertiesForRent > 0) || (agentType === 'sales & lettings'))
    );
  });

  const currentAgents = filteredAgents.slice(indexOfFirstAgent, indexOfLastAgent);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Layout>
      <div className="estateagentscontainer">
        {/* EstateAgentsContainer2 Section */}
        <div className="container-fluid estateagentscontainer2-container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="row estateagents-hero-margin-top">
                <h1 className="text-white text-center">Find Estate Agents in the UK</h1>
                <h5 className="text-white text-center fw-normal">With a network of 1,157 estate agents across the UK, finding the ideal estate agents on PP+ property today.</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-11 col-sm-12">
            <div className="estateagentsoverlay p-4">
              <div className="row mt-3">
                <span className="fw-medium fs-5">Enter Location</span>
              </div>
              <div className="row mt-3">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Text input with dropdown button"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                  <button className="btn btn-outline-dark dropdown-toggle fw-medium estateagentsoverlay-height-1" type="button" data-bs-toggle="dropdown" aria-expanded="false">This Area Only</button>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li><a className="dropdown-item" href="#">Action</a></li>
                    <li><a className="dropdown-item" href="#">Another action</a></li>
                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                  </ul>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <label htmlFor="" className="fw-medium mb-3">Agents Name</label>
                  <input
                    type="text"
                    placeholder="Full Name"
                    className="form-control text-secondary estateagentsoverlay-height-1"
                    name=""
                    id=""
                    value={agentName}
                    onChange={(e) => setAgentName(e.target.value)}
                  />
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12">
                  <label htmlFor="" className="fw-medium mb-3">Agents Type</label>
                  <div className="row estateagentsoverlay-height-1">
                    <div className="d-flex align-items-center">
                      <div className="form-check me-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="agentType"
                          id="salesLettings"
                          value="sales & lettings"
                          onChange={(e) => setAgentType(e.target.value)}
                        />
                        <label className="form-check-label" htmlFor="salesLettings">
                          <small className="estate-agents-small">Sales & Lettings</small>
                        </label>
                      </div>
                      <div className="form-check me-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="agentType"
                          id="sales"
                          value="sales"
                          onChange={(e) => setAgentType(e.target.value)}
                        />
                        <label className="form-check-label" htmlFor="sales">
                          <small className="estate-agents-small">Sales</small>
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="agentType"
                          id="lettings"
                          value="lettings"
                          onChange={(e) => setAgentType(e.target.value)}
                        />
                        <label className="form-check-label" htmlFor="lettings">
                          <small className="estate-agents-small">Lettings</small>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12">
                  <label htmlFor="" className="fw-medium text-white mb-3 ms-5">Btn</label>
                  <input
                    type="button"
                    className="btn btn-primary w-100 fw-medium estateagentsoverlay-btn"
                    value="Search Agent"
                    onClick={() => setCurrentPage(1)} // Reset to first page on search
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* EstateAgentsContainer3 Section */}
        <div className="estateagentscontainer3">
          <div className="container-fluid estateagentscontainer3-container mt-5">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-sm-12">
                <div className="row">
                  <h3>All Estate Agents in the UK</h3>
                </div>
                <div className="row mb-0">
                  <h5>{filteredAgents.length} estate agents</h5>
                </div>
                <div className="row justify-content-between mt-3">
                  {currentAgents.map((agent, index) => (
                    <AgentCard key={index} agent={agent} />
                  ))}
                </div>
                <div className="row mt-5">
                  <nav aria-label="Page navigation" className="d-flex justify-content-center">
                    <ul className="pagination">
                      {Array.from({ length: Math.ceil(filteredAgents.length / agentsPerPage) }, (_, i) => (
                        <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                          <button className="page-link" onClick={() => paginate(i + 1)}>{i + 1}</button>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default EstateAgents;