import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import ListingAgentContainer2 from "../Components/ListingAgentContainer2/ListingAgentContainer2";
import ListingAgentContainer3 from "../Components/ListingAgentContainer3/ListingAgentContainer3";

const ListingAgent = () => {
    return (
        <div className="listingagent">
            <div className="container-fluid">

                <div className="row">

                    <div className="col-lg-12">
                        <div className="container-box">
                            <Header />
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="container-box">
                            <ListingAgentContainer2 />
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="container-box">
                            <ListingAgentContainer3 />
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="container-box">
                            <Footer />
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default ListingAgent;