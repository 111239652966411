import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";

const options = [
  "Enquiry Type 1",
  "Enquiry Type 2",
  "Enquiry Type 3"
];

const inputClass = "form-control form-control-2 fw-medium";

const { textBuyerModal, iconBuyerModal, textDealerModal, iconDealerModal } = {
  textBuyerModal: "I am a car buyer wanting to contact you",
  iconBuyerModal: <i className="bi bi-person-fill me-3 fs-4"></i>,
  textDealerModal: "I am a car dealer wanting to contact you",
  iconDealerModal: <i className="bi bi-car-front-fill me-3 fs-4"></i>
};

const ContactUs = () => {
  return (
    <div className="container-fluid">

      <Header />

      <div className="container-box">
        <div className="row justify-content-center">
          <div className="col-11 col-lg-8">

            <div className="row text-center mt-5 mb-5">
              <h1 className="display-5">Contact us</h1>
              <p className="text-dark">
                Whether you are a car buyer or a car retailer, we want
                everyone to have the best possible experience.
              </p>
            </div>

            <div className="row bg-warning d-flex align-items-center mx-1">
              <div className="col-auto">
                <i className="bi bi-exclamation-triangle fs-3"></i>
              </div>
              <div className="col-11">
                <h5 className="text-dark mt-2 fw-bold mt-3">PP+Cars is an advertising platform</h5>
                <p className="text-dark mt-0 pt-0">
                  It does not own any of the cars shown on this website. If you have any
                  problems or concerns about a car you are buying or have already bought,
                  please contact the retailer in question.
                </p>
              </div>
            </div>

            <div className="row bg-white d-flex align-items-center mx-1 mt-5">
              <h4 className="text-dark mt-2 fw-bold mt-3">Want to send us a message?</h4>
              <p className="text-dark mt-0 pt-0">
                If there is anything you feel we could improve, or if you just want to give us
                some general feedback, please select from the options below. If you are making
                a complaint, please select 'Make a complaint' as the inquiry reason.
              </p>
              <div className="row m-0 mt-4">
                <div className="col-xs-12 com-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <button
                    className="form-control text-start text-white align-items-center contactUsBtn mb-4"
                    type="button"
                    data-toggle="modal"
                    data-target="#contactUsPersonModal"
                  >
                    <div className="m-2">
                      <i className="bi bi-person-fill me-3 fs-5"></i>
                      <span>I am a car buyer wanting to contact you</span>
                    </div>
                  </button>
                  {/* modal */}
                  <div className="modal fade bd-example-modal-lg" id="contactUsPersonModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                      <div className="modal-content">
                        <div className="modal-body">
                          <div className="container-fluid">
                            <div className="row text-end">
                              <i className="bi bi-x-lg close ms-auto" data-dismiss="modal" aria-label="Close"></i>
                            </div>
                            <div className="row align-items-center justify-content-between">
                              <div className="m-2">
                                {iconBuyerModal}
                                <span className="fs-5">{textBuyerModal}</span>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-12">
                                <div className="col-6">
                                  <label className="form-label fw-bold">Enquiry Type:</label>
                                  <select name="" className="form-control text-danger" id="">
                                    <option value="" disabled selected hidden>--- None ---</option>
                                    {options.map((option, index) => (
                                      <option key={index} value={option} className="text-dark">{option}</option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div className="col-12 mt-4">
                                <div>
                                  <label className="form-label fw-bold">Your Details:</label>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="mb-4">
                                  <input type="text" className={inputClass} placeholder="First Name*" />
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="mb-4">
                                  <input type="text" className={inputClass} placeholder="Last Name*" />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <div className="mb-4">
                                  <input type="email" className={inputClass} placeholder="Email Address*" />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <div className="mb-4">
                                  <textarea className="form-control fw-medium" id="exampleFormControlTextarea1" rows="3" placeholder="Tell us how we can help*"></textarea>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <p className="fw-medium">
                                PP+Cars uses your details in accordance with our <span className="cardetailcontainer3-gradient">privacy policy. </span>
                                By enquiring you agree that we will pass your details to the seller
                                who will use your details in accordance with their privacy policy.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <div className="row w-100 justify-content-center">
                            <input className="cardetailcontainer3-btn-2 fw-medium" type="button" value="Send Message" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 com-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <button
                    className="form-control text-start text-white align-items-center contactUsBtn mb-4"
                    type="button"
                    data-toggle="modal"
                    data-target="#contactUsDealerModal"
                  >
                    <div className="m-2">
                      <i className="bi bi-car-front-fill me-3 fs-5"></i>
                      <span>I am a car dealer wanting to contact you</span>
                    </div>
                  </button>
                  {/* modal */}
                  <div className="modal fade bd-example-modal-lg" id="contactUsDealerModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                      <div className="modal-content">
                        <div className="modal-body">
                          <div className="container-fluid">
                            <div className="row text-end">
                              <i className="bi bi-x-lg close ms-auto" data-dismiss="modal" aria-label="Close"></i>
                            </div>
                            <div className="row align-items-center justify-content-between">
                              <div className="m-2">
                                {iconDealerModal}
                                <span className="fs-5">{textDealerModal}</span>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-12">
                                <div className="col-6">
                                  <label className="form-label fw-bold">Enquiry Type:</label>
                                  <select name="" className="form-control text-danger" id="">
                                    <option value="" disabled selected hidden>--- None ---</option>
                                    {options.map((option, index) => (
                                      <option key={index} value={option} className="text-dark">{option}</option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div className="col-12 mt-4">
                                <div>
                                  <label className="form-label fw-bold">Your Details:</label>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="mb-4">
                                  <input type="text" className={inputClass} placeholder="First Name*" />
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="mb-4">
                                  <input type="text" className={inputClass} placeholder="Last Name*" />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6">
                                <div className="mb-4">
                                  <input type="email" className={inputClass} placeholder="Company Name*" />
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="mb-4">
                                  <input type="email" className={inputClass} placeholder="Email Address*" />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6">
                                <div className="mb-4">
                                  <input type="email" className={inputClass} placeholder="Phone Number*" />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <div className="mb-4">
                                  <textarea className="form-control fw-medium" id="exampleFormControlTextarea1" rows="3" placeholder="Tell us how we can help*"></textarea>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <p className="fw-medium">
                                PP+Cars uses your details in accordance with our <span className="cardetailcontainer3-gradient">privacy policy. </span>
                                By enquiring you agree that we will pass your details to the seller
                                who will use your details in accordance with their privacy policy.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <div className="row w-100 justify-content-center">
                            <input className="cardetailcontainer3-btn-2 fw-medium" type="button" value="Send Message" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <Footer />

    </div>
  )
}

export default ContactUs;