import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './PropertyDetailFirmContainer2.css';

function PropertyDetailFirmContainer2() {
    return (
        <div className="propertydetailfirmcontainer2">
            <div className="container-fluid propertydetailfirmcontainer2-container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-12 col-sm-12">

                        <div className="row">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item fs-6">
                                        <a href="/" className="propertydetailfirmcontainer2-a">
                                            <span className="propertydetailfirmcontainer2-p-gradient">
                                                Home
                                            </span>
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item fs-6">
                                        <a href="#" className="propertydetailfirmcontainer2-a">
                                            <span className="propertydetailfirmcontainer2-p-gradient">
                                                Listing
                                            </span>
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item fs-6">
                                        <a href="#" className="propertydetailfirmcontainer2-a">
                                            <span className="propertydetailfirmcontainer2-p-gradient">
                                                Property Detail
                                            </span>
                                        </a>
                                    </li>
                                </ol>
                            </nav>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default PropertyDetailFirmContainer2;