import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './PropertyDetailPrivateContainer3.css';
import propertyDetailContainer3ImgMain from '../../Assets/Images/PropertyDetail/propertydetailcontainer3-img-main.png';
import propertyDetailContainer3Img1 from '../../Assets/Images/PropertyDetail/propertydetailcontainer3-img-1.png';
import propertyDetailContainer3Img2 from '../../Assets/Images/PropertyDetail/propertydetailcontainer3-img-2.png';
import propertyDetailContainer3Img3 from '../../Assets/Images/PropertyDetail/propertydetailcontainer3-img-3.png';
import propertyDetailContainer3Img4 from '../../Assets/Images/PropertyDetail/propertydetailcontainer3-img-4.png';
import save from '../../Assets/Images/PropertyDetail/save.png';
import propertyDetailContainer3Icon1 from '../../Assets/Images/PropertyDetail/propertydetailcontainer3-icon-1.png';
import propertyDetailContainer3Icon2 from '../../Assets/Images/PropertyDetail/propertydetailcontainer3-icon-2.png';
import propertyDetailContainer3IconBrand from '../../Assets/Images/PropertyDetail/propertydetailcontainer3-img-brand.png';
import propertyDetailContainer3LabelIcon from '../../Assets/Images/PropertyDetail/propertydetailcontainer3-label-icon.png';

function PropertyDetailPrivateContainer3() {
  return (
    <div className="propertydetailprivatecontainer3">
      <div className="container-fluid propertydetailprivatecontainer3-container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-sm-12">

            <div className="row">
              <div className="col-lg-8">

                <div className="row">
                  <img src={propertyDetailContainer3ImgMain} alt="" />
                </div>

                <div className="row mt-3">
                  <div className="col-3">
                    <img src={propertyDetailContainer3Img1} className="w-100" alt="" />
                  </div>
                  <div className="col-3">
                    <img src={propertyDetailContainer3Img2} className="w-100" alt="" />
                  </div>
                  <div className="col-3">
                    <img src={propertyDetailContainer3Img3} className="w-100" alt="" />
                  </div>
                  <div className="col-3">
                    <img src={propertyDetailContainer3Img4} className="w-100" alt="" />
                  </div>
                </div>
                <hr className="mt-5" />

                <div className="row mt-5">
                  <div className="col-12">
                    <h3>Overview</h3>
                  </div>
                </div>

                <div className="row w-100 d-flex justify-content-between mt-3">
                  <div className="col-5">
                    <span className="text-secondary fw-medium fs-5">Registration Year</span>
                    <span className="fw-medium fs-5 ms-4">2021</span>
                    <hr />
                  </div>
                  <div className="col-5">
                    <span className="text-secondary fw-medium fs-5">Doors</span>
                    <span className="fw-medium fs-5 ms-4">4</span>
                    <hr />
                  </div>
                </div>

                <div className="row w-100 d-flex justify-content-between mt-3">
                  <div className="col-5">
                    <span className="text-secondary fw-medium fs-5">Mileage</span>
                    <span className="fw-medium fs-5 ms-4">16000 miles</span>
                    <hr />
                  </div>
                  <div className="col-5">
                    <span className="text-secondary fw-medium fs-5">Engine Size</span>
                    <span className="fw-medium fs-5 ms-4">1.5 L</span>
                    <hr />
                  </div>
                </div>

                <div className="row w-100 d-flex justify-content-between mt-3">
                  <div className="col-5">
                    <span className="text-secondary fw-medium fs-5">Fuel</span>
                    <span className="fw-medium fs-5 ms-4">Petrol</span>
                    <hr />
                  </div>
                  <div className="col-5">
                    <span className="text-secondary fw-medium fs-5">Transmission</span>
                    <span className="fw-medium fs-5 ms-4">Automatic</span>
                    <hr />
                  </div>
                </div>

                <div className="row w-100 d-flex justify-content-between mt-3">
                  <div className="col-5">
                    <span className="text-secondary fw-medium fs-5">Seat</span>
                    <span className="fw-medium fs-5 ms-4">4</span>
                    <hr />
                  </div>
                  <div className="col-5">
                    <span className="text-secondary fw-medium fs-5">Registration Number</span>
                    <span className="fw-medium fs-5 ms-4">ST22AVR</span>
                    <hr />
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-12">
                    <h3>Description</h3>
                  </div>
                </div>

                <div className="row w-100 mt-3">
                  <div className="col-10">
                    <span className="text-secondary fw-medium fs-5">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                      Class aptent taciti sociosqu ad litora torquent per conubia nostra,
                      per inceptos himenaeos. Curabitur tempus urna at turpis condimentum
                      lobortis. Ut commodo efficitur neque. Ut diam quam, semper iaculis
                      condimentum ac, vestibulum eu.
                    </span>
                    <br />
                    <span className="fw-medium fs-5 propertydetailprivatecontainer3-gradient">
                      Read More
                    </span>
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-12">
                    <h3>Features and Specs</h3>
                  </div>
                </div>

                <hr className="w-75 mb-0" />
                <div className="row w-100 mt-2">
                  <a className="fs-5 fw-medium propertydetailprivatecontainer3-features-specs mt-2 mb-2 text-decoration-none text-secondary" data-bs-toggle="collapse" href="#featuresSpecsCollapse1" role="button" aria-expanded="false" aria-controls="featuresSpecsCollapse1">
                    Interior Features
                  </a>
                  <div className="collapse" id="featuresSpecsCollapse1">
                    <div className="card card-body w-75">
                      <ul className="fw-medium fs-6 text-secondary">
                        <li>List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <hr className="w-75 mb-0 mt-2" />
                <div className="row w-100 mt-2">
                  <a className="fs-5 fw-medium propertydetailprivatecontainer3-features-specs mt-2 mb-2 text-decoration-none text-secondary" data-bs-toggle="collapse" href="#featuresSpecsCollapse2" role="button" aria-expanded="false" aria-controls="featuresSpecsCollapse2">
                    Exterior Features
                  </a>
                  <div className="collapse" id="featuresSpecsCollapse2">
                    <div className="card card-body w-75">
                      <ul className="fw-medium fs-6 text-secondary">
                        <li>List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <hr className="w-75 mb-0 mt-2" />
                <div className="row w-100 mt-2">
                  <a className="fs-5 fw-medium propertydetailprivatecontainer3-features-specs mt-2 mb-2 text-decoration-none text-secondary" data-bs-toggle="collapse" href="#featuresSpecsCollapse3" role="button" aria-expanded="false" aria-controls="featuresSpecsCollapse3">
                    Safety
                  </a>
                  <div className="collapse" id="featuresSpecsCollapse3">
                    <div className="card card-body w-75">
                      <ul className="fw-medium fs-6 text-secondary">
                        <li>List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <hr className="w-75 mb-0 mt-2" />
                <div className="row w-100 mt-2">
                  <a className="fs-5 fw-medium propertydetailprivatecontainer3-features-specs mt-2 mb-2 text-decoration-none text-secondary" data-bs-toggle="collapse" href="#featuresSpecsCollapse4" role="button" aria-expanded="false" aria-controls="featuresSpecsCollapse4">
                    Driver Convenience
                  </a>
                  <div className="collapse" id="featuresSpecsCollapse4">
                    <div className="card card-body w-75">
                      <ul className="fw-medium fs-6 text-secondary">
                        <li>List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <hr className="w-75 mb-0 mt-2" />
                <div className="row w-100 mt-2">
                  <a className="fs-5 fw-medium propertydetailprivatecontainer3-features-specs mt-2 mb-2 text-decoration-none text-secondary" data-bs-toggle="collapse" href="#featuresSpecsCollapse5" role="button" aria-expanded="false" aria-controls="featuresSpecsCollapse5">
                    Size & Dimensions
                  </a>
                  <div className="collapse" id="featuresSpecsCollapse5">
                    <div className="card card-body w-75">
                      <ul className="fw-medium fs-6 text-secondary">
                        <li>List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <hr className="w-75 mb-0 mt-2" />
                <div className="row w-100 mt-2">
                  <a className="fs-5 fw-medium propertydetailprivatecontainer3-features-specs mt-2 mb-2 text-decoration-none text-secondary" data-bs-toggle="collapse" href="#featuresSpecsCollapse6" role="button" aria-expanded="false" aria-controls="featuresSpecsCollapse6">
                    Engine & Suspensions
                  </a>
                  <div className="collapse" id="featuresSpecsCollapse6">
                    <div className="card card-body w-75">
                      <ul className="fw-medium fs-6 text-secondary">
                        <li>List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <hr className="w-75 mb-0 mt-2" />
                <div className="row w-100 mt-2">
                  <a className="fs-5 fw-medium propertydetailprivatecontainer3-features-specs mt-2 mb-2 text-decoration-none text-secondary" data-bs-toggle="collapse" href="#featuresSpecsCollapse7" role="button" aria-expanded="false" aria-controls="featuresSpecsCollapse7">
                    Additional Options
                  </a>
                  <div className="collapse" id="featuresSpecsCollapse7">
                    <div className="card card-body w-75">
                      <ul className="fw-medium fs-6 text-secondary">
                        <li>List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                        <li className="mt-3">List Item Lorem ipsum dolor sit amet.</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-12">
                    <h3>Running Cost</h3>
                  </div>
                </div>

                <div className="row w-100 mt-3">
                  <div className="col-10">
                    <span className="text-secondary fw-normal fs-5">
                      Running costs are the routine costs associated with operating your vehicle, comprising
                      fuel, maintenance, insurance, tax, and additional expenditures that should be taken into account.
                      The figures presented are derived from estimated averages specific to this car; however,
                      variations may occur depending on individual factors such as your driving style,
                      driving history, and location.
                    </span>
                    <br />
                  </div>
                </div>

                <div className="row w-100 d-flex mt-3 ">
                  <div className="col-5">
                    <span className="text-secondary fw-normal fs-5">
                      Vehicle Tax
                      <img src={propertyDetailContainer3LabelIcon} className="propertydetailprivatecontainer3-label-icon" alt="" />
                    </span>
                    <span className="fw-medium fs-5 ms-4">£220/year</span>
                    <hr />
                  </div>
                  <div className="col-5">
                    <span className="text-secondary fw-normal fs-5">
                      Range
                      <img src={propertyDetailContainer3LabelIcon} className="propertydetailprivatecontainer3-label-icon" alt="" />
                    </span>
                    <span className="fw-medium fs-5 ms-4">£220/year</span>
                    <hr />
                  </div>
                </div>

                <div className="row w-100 d-flex mt-3">
                  <div className="col-5">
                    <span className="text-secondary fw-normal fs-5">
                      Insurance
                      <img src={propertyDetailContainer3LabelIcon} className="propertydetailprivatecontainer3-label-icon" alt="" />
                    </span>
                    <span className="fw-medium fs-5 ms-4">Group 16</span>
                    <hr />
                  </div>
                  <div className="col-5">
                    <span className="text-secondary fw-normal fs-5">
                      Cost to Fuel
                      <img src={propertyDetailContainer3LabelIcon} className="propertydetailprivatecontainer3-label-icon" alt="" />
                    </span>
                    <span className="fw-medium fs-5 ms-4">Group 16</span>
                    <hr />
                  </div>
                </div>

                <div className="row w-100 d-flex mt-3">
                  <div className="col-5">
                    <span className="text-secondary fw-normal fs-5">
                      Miles per Gallon
                      <img src={propertyDetailContainer3LabelIcon} className="propertydetailprivatecontainer3-label-icon" alt="" />
                    </span>
                    <span className="fw-medium fs-5 ms-4">68 MPG</span>
                    <hr />
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-12">
                    <h3>History
                      <img src={propertyDetailContainer3LabelIcon} className="propertydetailprivatecontainer3-label-icon" alt="" />
                    </h3>
                  </div>
                </div>

                <div className="row w-100 mt-3">
                  <div className="col-10">
                    <span className="text-secondary fw-normal fs-5">
                      The history records for this property are shown below.
                    </span>
                    <br />
                  </div>
                </div>

                <hr className="w-75 mb-0" />
                <div className="row w-100 mt-2">
                  <a className="fs-5 fw-medium propertydetailprivatecontainer3-features-specs  text-secondary" data-bs-toggle="collapse" href="#serviceHistoryCollapse1" role="button" aria-expanded="false" aria-controls="serviceHistoryCollapse1">
                    <h4 className="fw-normal mt-3">Service Date <span className="fs-5 ms-5 text-dark">09/08/2023</span></h4>
                  </a>
                  <div className="collapse" id="serviceHistoryCollapse1">
                    <div className="card card-body w-75">
                      <div className="row">
                        <div className="col-6">
                          <span className="text-secondary fw-normal fs-5">
                            Mileage
                          </span>
                          <span className="fw-medium fs-5 ms-4">20,000 miles</span>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-6">
                          <span className="text-secondary fw-normal fs-5">
                            Service Record Format
                          </span>
                          <span className="fw-medium fs-5 ms-4">Service Document</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="w-75 mb-0" />
                <div className="row w-100 mt-2">
                  <a className="fs-5 fw-medium propertydetailprivatecontainer3-features-specs  text-secondary" data-bs-toggle="collapse" href="#serviceHistoryCollapse2" role="button" aria-expanded="false" aria-controls="serviceHistoryCollapse2">
                    <h4 className="fw-normal mt-3">Service Date <span className="fs-5 ms-5 text-dark">09/08/2023</span></h4>
                  </a>
                  <div className="collapse" id="serviceHistoryCollapse2">
                    <div className="card card-body w-75">
                      <div className="row">
                        <div className="col-6">
                          <span className="text-secondary fw-normal fs-5">
                            Mileage
                          </span>
                          <span className="fw-medium fs-5 ms-4">20,000 miles</span>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-6">
                          <span className="text-secondary fw-normal fs-5">
                            Service Record Format
                          </span>
                          <span className="fw-medium fs-5 ms-4">Service Document</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="w-75 mb-0" />
                <div className="row w-100 mt-2">
                  <a className="fs-5 fw-medium propertydetailprivatecontainer3-features-specs  text-secondary" data-bs-toggle="collapse" href="#serviceHistoryCollapse3" role="button" aria-expanded="false" aria-controls="serviceHistoryCollapse3">
                    <h4 className="fw-normal mt-3">Service Date <span className="fs-5 ms-5 text-dark">09/08/2023</span></h4>
                  </a>
                  <div className="collapse" id="serviceHistoryCollapse3">
                    <div className="card card-body w-75">
                      <div className="row">
                        <div className="col-6">
                          <span className="text-secondary fw-normal fs-5">
                            Mileage
                          </span>
                          <span className="fw-medium fs-5 ms-4">20,000 miles</span>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-6">
                          <span className="text-secondary fw-normal fs-5">
                            Service Record Format
                          </span>
                          <span className="fw-medium fs-5 ms-4">Service Document</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="w-75 mb-0" />
                <div className="row w-100 mt-2">
                  <a className="fs-5 fw-medium propertydetailprivatecontainer3-features-specs  text-secondary" data-bs-toggle="collapse" href="#serviceHistoryCollapse4" role="button" aria-expanded="false" aria-controls="serviceHistoryCollapse4">
                    <h4 className="fw-normal mt-3">Service Date <span className="fs-5 ms-5 text-dark">09/08/2023</span></h4>
                  </a>
                  <div className="collapse" id="serviceHistoryCollapse4">
                    <div className="card card-body w-75">
                      <div className="row">
                        <div className="col-6">
                          <span className="text-secondary fw-normal fs-5">
                            Mileage
                          </span>
                          <span className="fw-medium fs-5 ms-4">20,000 miles</span>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-6">
                          <span className="text-secondary fw-normal fs-5">
                            Service Record Format
                          </span>
                          <span className="fw-medium fs-5 ms-4">Service Document</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="w-75 mb-0" />
                <div className="row w-100 mt-2">
                  <a className="fs-5 fw-medium propertydetailprivatecontainer3-features-specs  text-secondary" data-bs-toggle="collapse" href="#serviceHistoryCollapse5" role="button" aria-expanded="false" aria-controls="serviceHistoryCollapse5">
                    <h4 className="fw-normal mt-3">Service Date <span className="fs-5 ms-5 text-dark">09/08/2023</span></h4>
                  </a>
                  <div className="collapse" id="serviceHistoryCollapse5">
                    <div className="card card-body w-75">
                      <div className="row">
                        <div className="col-6">
                          <span className="text-secondary fw-normal fs-5">
                            Mileage
                          </span>
                          <span className="fw-medium fs-5 ms-4">20,000 miles</span>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-6">
                          <span className="text-secondary fw-normal fs-5">
                            Service Record Format
                          </span>
                          <span className="fw-medium fs-5 ms-4">Service Document</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div className="col-lg-4">
                <div className="row d-flex w-100 justify-content-between">
                  <div className="col-lg-6 col-md-6 col-sm-8">
                    <label htmlFor="" className="propertydetailprivatecontainer3-label-1 text-secondary">Used</label>
                    <label htmlFor="" className="propertydetailprivatecontainer3-label-2 ms-3">New</label>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-4 d-flex justify-content-end align-items-center">
                    <img src={save} className="propertydetailprivatecontainer3-save-icon" alt="" />
                  </div>
                </div>
                <div className="row mt-3">
                  <h3 className="fw-medium">HOUSE - Test House</h3>
                </div>
                <div className="row mt-3">
                  <h5 className="fw-normal">4 bed detached house for sale</h5>
                </div>
                <div className="row mt-3">
                  <h2>£1,200,300</h2>
                </div>
                <hr />
                <div className="row d-flex w-100">
                  <div className="col-lg-6 col-md-6 col-sm-8">
                    <label htmlFor="" className="propertydetailprivatecontainer3-label-2 ms-3">Private</label>
                  </div>
                </div>
                <div className="row d-flex w-100 mt-4">
                  <div className="col-1">
                    <img src={propertyDetailContainer3Icon1} className="propertydetailprivatecontainer3-icon me-0" alt="" />
                  </div>
                  <div className="col-auto">
                    <span className="propertydetailprivatecontainer3-gradient ms-0">(01908) 942082</span>
                  </div>
                </div>
                <div className="row d-flex w-100 mt-4">
                  <div className="col-1">
                    <img src={propertyDetailContainer3Icon2} className="propertydetailprivatecontainer3-icon me-0" alt="" />
                  </div>
                  <div className="col-auto">
                    <span className="propertdetailprivatecontainer3-gradient ms-0">Damside, Keighley, West Yorkshire, BD21 1QD</span><br />
                    <span className="text-secondary">(4.3 miles)</span>
                  </div>
                </div>
                <div className="row mt-5 justify-content-center">
                  <input className="propertydetailprivatecontainer3-btn w-100" type="button" value="Message Seller" data-toggle="modal" data-target="#propertyDetailPrivateMessageModal" />
                  {/* Modal */}
                  <div className="modal fade bd-example-modal-lg" id="propertyDetailPrivateMessageModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                      <div className="modal-content">
                        <div className="modal-header justify-content-between">
                          <h5 className="modal-title" id="exampleModalLongTitle">Message to Owner</h5>
                          <button className="propertydetailprivatecontainer3-message-btn close" data-dismiss="modal" aria-label="Close">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                            </svg>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="container-fluid">
                            <div className="row align-items-center">
                              <div className="col-3">
                                <img src={propertyDetailContainer3ImgMain} className="w-100" alt="" />
                              </div>
                              <div className="col-auto">
                                <h3 className="fw-medium">HOUSE - Test House</h3>
                                <h5 className="fw-normal">4 bed detached house for sale</h5>
                              </div>
                            </div>
                            <div className="row mt-4">
                              <div className="col-auto">
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                  <label className="form-check-label fw-medium" htmlFor="flexCheckDefault">
                                    <small>Is this still available?</small>
                                  </label>
                                </div>
                              </div>
                              <div className="col-auto">
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                  <label className="form-check-label fw-medium" htmlFor="flexCheckDefault">
                                    <small>Request Home Tour</small>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-6">
                                <div className="mb-3">
                                  <label className="form-label fw-medium"><small>First name</small></label>
                                  <input type="text" className="form-control form-control-2 fw-medium" placeholder="e. g. John" />
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="mb-3">
                                  <label className="form-label fw-medium"><small>Last name</small></label>
                                  <input type="text" className="form-control form-control-2 fw-medium" placeholder="e. g. Doe" />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <div className="mb-3">
                                  <label className="form-label fw-medium"><small>Email Address</small></label>
                                  <input type="email" className="form-control form-control-2 fw-medium" placeholder="example@email.com" />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <div className="mb-3">
                                  <label className="form-label fw-medium"><small>Phone Number</small></label>
                                  <input type="text" className="form-control form-control-2 fw-medium" placeholder="e. g. 0777 000 000" />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <div className="mb-3">
                                  <label htmlFor="exampleFormControlTextarea1" className="form-label fw-medium"><small>Message</small></label>
                                  <textarea className="form-control fw-medium" id="exampleFormControlTextarea1" rows="5" placeholder="Message"></textarea>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <p className="fw-medium">
                                PP+Cars uses your details in accordance with our <span className="propertydetailprivatecontainer3-gradient">privacy policy. </span>
                                By enquiring you agree that we will pass your details to the seller
                                who will use your details in accordance with their privacy policy.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <div className="row w-100 justify-content-center">
                            <input className="propertydetailprivatecontainer3-btn-2 fw-medium" type="button" value="Send Message" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <input className="propertydetailprivatecontainer3-btn w-100 mt-3" type="button" value="Request Home Tour" data-toggle="modal" data-target="#propertyDetailPrivateRequestModal" />
                  {/* Modal */}
                  <div className="modal fade bd-example-modal-lg" id="propertyDetailPrivateRequestModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                      <div className="modal-content">
                        <div className="modal-header justify-content-between">
                          <h5 className="modal-title" id="exampleModalLongTitle">Schedule Viewing</h5>
                          <button className="propertydetailprivatecontainer3-message-btn close" data-dismiss="modal" aria-label="Close">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                            </svg>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col-12">
                                <div className="mb-3">
                                  <label className="form-label fw-medium"><small>Choose a Date</small></label><br />
                                  <input type="date" className="form-control text-secondary form-control-2 fw-medium" placeholder='Select Day' name="" id="" />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <div className="mb-3">
                                  <label className="form-label fw-medium"><small>Select Time</small></label>
                                  <button className="form-control text-secondary text-start form-control-2 fw-medium" type="button" data-bs-toggle="dropdown" aria-expanded="false">Select Time</button>
                                  <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="#">09:00 am</a></li>
                                    <li><a className="dropdown-item" href="#">10:00 am</a></li>
                                    <li><a className="dropdown-item" href="#">11:00 am</a></li>
                                    <li><a className="dropdown-item" href="#">12:00 pm</a></li>
                                    <li><a className="dropdown-item" href="#">01:00 pm</a></li>
                                    <li><a className="dropdown-item" href="#">02:00 pm</a></li>
                                    <li><a className="dropdown-item" href="#">03:00 pm</a></li>
                                    <li><a className="dropdown-item" href="#">04:00 pm</a></li>
                                    <li><a className="dropdown-item" href="#">05:00 pm</a></li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <div className="row w-100 justify-content-center">
                            <input className="propertydetailprivatecontainer3-btn-2 fw-medium" type="button" value="Schedule Viewing" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default PropertyDetailPrivateContainer3;