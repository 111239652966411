import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import ListingForSaleContainer3 from "../Components/ListingForSaleContainer3/ListingForSaleContainer3";
import ListingForSaleContainer2 from "../Components/ListingForSaleContainer2/ListingForSaleContainer2";

const ListingForSale = () => {
  return (
    <div className="listingforsale">
      <div className="container-fluid">

        <div className="row">

          <div className="col-lg-12">
            <div className="container-box">
              <Header />
            </div>
          </div>

          <div className="col-lg-12">
            <div className="container-box">
              <ListingForSaleContainer2 />
            </div>
          </div>

          <div className="col-lg-12">
            <div className="container-box">
              <ListingForSaleContainer3 />
            </div>
          </div>

          <div className="col-lg-12">
            <div className="container-box">
              <Footer />
            </div>
          </div>

        </div>

      </div>
    </div>
  )
}

export default ListingForSale;