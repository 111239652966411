import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './ListingForRentList.css'
import mainImg from '../../Assets/Images/ListingForRent/listingforrentlist-img-main.png';
import img1 from '../../Assets/Images/ListingForRent/listingforrentlist-img-1.png';
import img2 from '../../Assets/Images/ListingForRent/listingforrentlist-img-2.png';
import img3 from '../../Assets/Images/ListingForRent/listingforrentlist-img-3.png';
import save from '../../Assets/Images/ListingForRent/save.png';
import bed from '../../Assets/Images/ListingForRent/listingforrentlist-icon-bed.png';
import bath from '../../Assets/Images/ListingForRent/listingforrentlist-icon-bath.png';
import brandLogo from '../../Assets/Images/ListingForRent/listingforrentlist-icon-brand.png';
import messageIcon from '../../Assets/Images/ListingForRent/listingforrentlist-icon-message.png';
import callIcon from '../../Assets/Images/ListingForRent/listingforrentlist-icon-call.png';

function ListingForRentList() {
    return (
        <div className="listingforrentlist">
            <div className="container-fluid listingforrentlist-container">
                <div className="row">

                    <div className="col-12 listingforrentlist-main-div">
                        <div className="row">

                            <div className="col-lg-5 col-md-5 col-sm-12">
                                <img src={mainImg} className="listingforrentlist-img" alt="" />
                            </div>

                            <div className="col-lg-7 col-md-7 col-sm-12 heightAuto">
                                <div className="row w-100 justify-content-end mt-2 listingforrentlistcustom-row-mobile">
                                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end align-items-center">
                                        <img src={save} className="listingforrentlist-save-icon" alt="" />
                                        <input type="button" className="listingforrentlist-btn fw-medium fs-6" value="Save" />
                                    </div>
                                </div>
                                <div className="row w-100 mt-3 listingforrentlistcustom-row-mobile">
                                    <span className="fw-medium fs-6">4 bed detached house for rent</span>
                                </div>
                                <div className="row w-100 justify-content-between mt-3 listingforrentlistcustom-row-mobile">
                                    <div className="col-auto">
                                        <span className="text-secondary fs-6">Knights Road, Oxford OX4</span>
                                    </div>
                                    <div className="col-auto">
                                        <span className="text-secondary fs-6">Guide Price</span>
                                    </div>
                                </div>
                                <div className="row w-100 justify-content-between align-items-center mt-3 listingforrentlistcustom-row-mobile">
                                    <div className="col-auto">
                                        <div className="row">
                                            <div className="col-auto">
                                                <p className="text-secondary">
                                                    <img src={bed} className="homecontainer4-icon" alt="" /> <span className="text-dark fs-6">4</span>
                                                </p>
                                            </div>
                                            &bull;
                                            <div className="col-auto">
                                                <p className="text-secondary">
                                                    <img src={bath} className="homecontainer4-icon" alt="" /> <span className="text-dark fs-6">2</span>
                                                </p>
                                            </div>
                                            &bull;
                                            <div className="col-auto">
                                                <p className="text-secondary">
                                                    <span className="text-dark fs-6">5,000</span> <span className="text-secondary">ft²</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <span className="fs-3 fw-bold">£12,300</span>
                                    </div>
                                </div>
                                <div className="row listingforrentlistcustom-row-1 listingforrentlistcustom-row-mobile">
                                    <span className="text-secondary fs-6">
                                        Borem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                                        Class aptent taciti sociosqu ad lit...
                                    </span>
                                </div>
                            </div>

                        </div>
                        <hr className="mt-0" />

                        <div className="row">

                            <div className="col-lg-5 col-md-5 col-sm-12">
                                <div className="row justify-content-between">
                                    <img src={img1} className="listingforrentlist-small-div" alt="" />
                                    <img src={img2} className="listingforrentlist-small-div" alt="" />
                                    <img src={img3} className="listingforrentlist-small-div" alt="" />
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-7 col-sm-12 heightAuto">
                                <div className="row w-100 text-secondary fs-6">
                                    <div className="col-auto">
                                        Added on 16/10/2023 by <span className="fw-medium"><u>John D Wood & Co</u></span>
                                    </div>
                                </div>
                                <div className="row w-100 fs-6 mt-1 mb-3">
                                    <div className="col-lg-4 col-md-5 col-sm-12">
                                        <img src={brandLogo} className="listingforrentlist-brand-logo" alt="" />
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-4 ms-auto">
                                        <img src={messageIcon} className="listingforrentlist-icon-message me-2" alt="" />
                                        <span><u>Message</u></span>
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-sm-4">
                                        <img src={callIcon} className="listingforrentlist-icon-call me-2" alt="" />
                                        <span><u>Call</u></span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-12 listingforrentlist-main-div mt-4">
                        <div className="row">

                            <div className="col-lg-5 col-md-5 col-sm-12">
                                <img src={mainImg} className="listingforrentlist-img" alt="" />
                            </div>

                            <div className="col-lg-7 col-md-7 col-sm-12 heightAuto">
                                <div className="row w-100 justify-content-between mt-2 listingforrentlistcustom-row-mobile">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <label htmlFor="" className="listingforrentlist-label fs-6">New Home</label>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end align-items-center">
                                        <img src={save} className="listingforrentlist-save-icon" alt="" />
                                        <input type="button" className="listingforrentlist-btn fw-medium fs-6" value="Save" />
                                    </div>
                                </div>
                                <div className="row w-100 mt-3 listingforrentlistcustom-row-mobile">
                                    <span className="fw-medium fs-6">4 bed detached house for rent</span>
                                </div>
                                <div className="row w-100 justify-content-between mt-3 listingforrentlistcustom-row-mobile">
                                    <div className="col-auto">
                                        <span className="text-secondary fs-6">Knights Road, Oxford OX4</span>
                                    </div>
                                    <div className="col-auto">
                                        <span className="text-secondary fs-6">Guide Price</span>
                                    </div>
                                </div>
                                <div className="row w-100 justify-content-between align-items-center mt-3 listingforrentlistcustom-row-mobile">
                                    <div className="col-auto">
                                        <div className="row">
                                            <div className="col-auto">
                                                <p className="text-secondary">
                                                    <img src={bed} className="homecontainer4-icon" alt="" /> <span className="text-dark fs-6">4</span>
                                                </p>
                                            </div>
                                            &bull;
                                            <div className="col-auto">
                                                <p className="text-secondary">
                                                    <img src={bath} className="homecontainer4-icon" alt="" /> <span className="text-dark fs-6">2</span>
                                                </p>
                                            </div>
                                            &bull;
                                            <div className="col-auto">
                                                <p className="text-secondary">
                                                    <span className="text-dark fs-6">5,000</span> <span className="text-secondary">ft²</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <span className="fs-3 fw-bold">£12,300</span>
                                    </div>
                                </div>
                                <div className="row listingforrentlistcustom-row-1 listingforrentlistcustom-row-mobile">
                                    <span className="text-secondary fs-6">
                                        Borem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                                        Class aptent taciti sociosqu ad lit...
                                    </span>
                                </div>
                            </div>

                        </div>
                        <hr className="mt-0" />

                        <div className="row">

                            <div className="col-lg-5 col-md-5 col-sm-12">
                                <div className="row justify-content-between">
                                    <img src={img1} className="listingforrentlist-small-div" alt="" />
                                    <img src={img2} className="listingforrentlist-small-div" alt="" />
                                    <img src={img3} className="listingforrentlist-small-div" alt="" />
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-7 col-sm-12 heightAuto">
                                <div className="row w-100 text-secondary fs-6">
                                    <div className="col-auto">
                                        Added on 16/10/2023 by <span className="fw-medium"><u>John D Wood & Co</u></span>
                                    </div>
                                </div>
                                <div className="row w-100 fs-6 mt-1 mb-3">
                                    <div className="col-lg-4 col-md-5 col-sm-12">
                                        <img src={brandLogo} className="listingforrentlist-brand-logo" alt="" />
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-4 ms-auto">
                                        <img src={messageIcon} className="listingforrentlist-icon-message me-2" alt="" />
                                        <span><u>Message</u></span>
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-sm-4">
                                        <img src={callIcon} className="listingforrentlist-icon-call me-2" alt="" />
                                        <span><u>Call</u></span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-12 listingforrentlist-main-div mt-4">
                        <div className="row">

                            <div className="col-lg-5 col-md-5 col-sm-12">
                                <img src={mainImg} className="listingforrentlist-img" alt="" />
                            </div>

                            <div className="col-lg-7 col-md-7 col-sm-12 heightAuto">
                                <div className="row w-100 justify-content-end mt-2 listingforrentlistcustom-row-mobile">
                                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end align-items-center">
                                        <img src={save} className="listingforrentlist-save-icon" alt="" />
                                        <input type="button" className="listingforrentlist-btn fw-medium fs-6" value="Save" />
                                    </div>
                                </div>
                                <div className="row w-100 mt-3 listingforrentlistcustom-row-mobile">
                                    <span className="fw-medium fs-6">4 bed detached house for rent</span>
                                </div>
                                <div className="row w-100 justify-content-between mt-3 listingforrentlistcustom-row-mobile">
                                    <div className="col-auto">
                                        <span className="text-secondary fs-6">Knights Road, Oxford OX4</span>
                                    </div>
                                    <div className="col-auto">
                                        <span className="text-secondary fs-6">Guide Price</span>
                                    </div>
                                </div>
                                <div className="row w-100 justify-content-between align-items-center mt-3 listingforrentlistcustom-row-mobile">
                                    <div className="col-auto">
                                        <div className="row">
                                            <div className="col-auto">
                                                <p className="text-secondary">
                                                    <img src={bed} className="homecontainer4-icon" alt="" /> <span className="text-dark fs-6">4</span>
                                                </p>
                                            </div>
                                            &bull;
                                            <div className="col-auto">
                                                <p className="text-secondary">
                                                    <img src={bath} className="homecontainer4-icon" alt="" /> <span className="text-dark fs-6">2</span>
                                                </p>
                                            </div>
                                            &bull;
                                            <div className="col-auto">
                                                <p className="text-secondary">
                                                    <span className="text-dark fs-6">5,000</span> <span className="text-secondary">ft²</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <span className="fs-3 fw-bold">£12,300</span>
                                    </div>
                                </div>
                                <div className="row listingforrentlistcustom-row-1 listingforrentlistcustom-row-mobile">
                                    <span className="text-secondary fs-6">
                                        Borem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                                        Class aptent taciti sociosqu ad lit...
                                    </span>
                                </div>
                            </div>

                        </div>
                        <hr className="mt-0" />

                        <div className="row">

                            <div className="col-lg-5 col-md-5 col-sm-12">
                                <div className="row justify-content-between">
                                    <img src={img1} className="listingforrentlist-small-div" alt="" />
                                    <img src={img2} className="listingforrentlist-small-div" alt="" />
                                    <img src={img3} className="listingforrentlist-small-div" alt="" />
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-7 col-sm-12 heightAuto">
                                <div className="row w-100 text-secondary fs-6">
                                    <div className="col-auto">
                                        Added on 16/10/2023 by <span className="fw-medium"><u>John D Wood & Co</u></span>
                                    </div>
                                </div>
                                <div className="row w-100 fs-6 mt-1 mb-3">
                                    <div className="col-lg-4 col-md-5 col-sm-12">
                                        <img src={brandLogo} className="listingforrentlist-brand-logo" alt="" />
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-4 ms-auto">
                                        <img src={messageIcon} className="listingforrentlist-icon-message me-2" alt="" />
                                        <span><u>Message</u></span>
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-sm-4">
                                        <img src={callIcon} className="listingforrentlist-icon-call me-2" alt="" />
                                        <span><u>Call</u></span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-12 listingforrentlist-main-div mt-4">
                        <div className="row">

                            <div className="col-lg-5 col-md-5 col-sm-12">
                                <img src={mainImg} className="listingforrentlist-img" alt="" />
                            </div>

                            <div className="col-lg-7 col-md-7 col-sm-12 heightAuto">
                                <div className="row w-100 justify-content-between mt-2 listingforrentlistcustom-row-mobile">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <label htmlFor="" className="listingforrentlist-label fs-6">New Home</label>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end align-items-center">
                                        <img src={save} className="listingforrentlist-save-icon" alt="" />
                                        <input type="button" className="listingforrentlist-btn fw-medium fs-6" value="Save" />
                                    </div>
                                </div>
                                <div className="row w-100 mt-3 listingforrentlistcustom-row-mobile">
                                    <span className="fw-medium fs-6">4 bed detached house for rent</span>
                                </div>
                                <div className="row w-100 justify-content-between mt-3 listingforrentlistcustom-row-mobile">
                                    <div className="col-auto">
                                        <span className="text-secondary fs-6">Knights Road, Oxford OX4</span>
                                    </div>
                                    <div className="col-auto">
                                        <span className="text-secondary fs-6">Guide Price</span>
                                    </div>
                                </div>
                                <div className="row w-100 justify-content-between align-items-center mt-3 listingforrentlistcustom-row-mobile">
                                    <div className="col-auto">
                                        <div className="row">
                                            <div className="col-auto">
                                                <p className="text-secondary">
                                                    <img src={bed} className="homecontainer4-icon" alt="" /> <span className="text-dark fs-6">4</span>
                                                </p>
                                            </div>
                                            &bull;
                                            <div className="col-auto">
                                                <p className="text-secondary">
                                                    <img src={bath} className="homecontainer4-icon" alt="" /> <span className="text-dark fs-6">2</span>
                                                </p>
                                            </div>
                                            &bull;
                                            <div className="col-auto">
                                                <p className="text-secondary">
                                                    <span className="text-dark fs-6">5,000</span> <span className="text-secondary">ft²</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <span className="fs-3 fw-bold">£12,300</span>
                                    </div>
                                </div>
                                <div className="row listingforrentlistcustom-row-1 listingforrentlistcustom-row-mobile">
                                    <span className="text-secondary fs-6">
                                        Borem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                                        Class aptent taciti sociosqu ad lit...
                                    </span>
                                </div>
                            </div>

                        </div>
                        <hr className="mt-0" />

                        <div className="row">

                            <div className="col-lg-5 col-md-5 col-sm-12">
                                <div className="row justify-content-between">
                                    <img src={img1} className="listingforrentlist-small-div" alt="" />
                                    <img src={img2} className="listingforrentlist-small-div" alt="" />
                                    <img src={img3} className="listingforrentlist-small-div" alt="" />
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-7 col-sm-12 heightAuto">
                                <div className="row w-100 text-secondary fs-6">
                                    <div className="col-auto">
                                        Added on 16/10/2023 by <span className="fw-medium"><u>John D Wood & Co</u></span>
                                    </div>
                                </div>
                                <div className="row w-100 fs-6 mt-1 mb-3">
                                    <div className="col-lg-4 col-md-5 col-sm-12">
                                        <img src={brandLogo} className="listingforrentlist-brand-logo" alt="" />
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-4 ms-auto">
                                        <img src={messageIcon} className="listingforrentlist-icon-message me-2" alt="" />
                                        <span><u>Message</u></span>
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-sm-4">
                                        <img src={callIcon} className="listingforrentlist-icon-call me-2" alt="" />
                                        <span><u>Call</u></span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-12 listingforrentlist-main-div mt-4">
                        <div className="row">

                            <div className="col-lg-5 col-md-5 col-sm-12">
                                <img src={mainImg} className="listingforrentlist-img" alt="" />
                            </div>

                            <div className="col-lg-7 col-md-7 col-sm-12 heightAuto">
                                <div className="row w-100 justify-content-end mt-2 listingforrentlistcustom-row-mobile">
                                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end align-items-center">
                                        <img src={save} className="listingforrentlist-save-icon" alt="" />
                                        <input type="button" className="listingforrentlist-btn fw-medium fs-6" value="Save" />
                                    </div>
                                </div>
                                <div className="row w-100 mt-3 listingforrentlistcustom-row-mobile">
                                    <span className="fw-medium fs-6">4 bed detached house for rent</span>
                                </div>
                                <div className="row w-100 justify-content-between mt-3 listingforrentlistcustom-row-mobile">
                                    <div className="col-auto">
                                        <span className="text-secondary fs-6">Knights Road, Oxford OX4</span>
                                    </div>
                                    <div className="col-auto">
                                        <span className="text-secondary fs-6">Guide Price</span>
                                    </div>
                                </div>
                                <div className="row w-100 justify-content-between align-items-center mt-3 listingforrentlistcustom-row-mobile">
                                    <div className="col-auto">
                                        <div className="row">
                                            <div className="col-auto">
                                                <p className="text-secondary">
                                                    <img src={bed} className="homecontainer4-icon" alt="" /> <span className="text-dark fs-6">4</span>
                                                </p>
                                            </div>
                                            &bull;
                                            <div className="col-auto">
                                                <p className="text-secondary">
                                                    <img src={bath} className="homecontainer4-icon" alt="" /> <span className="text-dark fs-6">2</span>
                                                </p>
                                            </div>
                                            &bull;
                                            <div className="col-auto">
                                                <p className="text-secondary">
                                                    <span className="text-dark fs-6">5,000</span> <span className="text-secondary">ft²</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <span className="fs-3 fw-bold">£12,300</span>
                                    </div>
                                </div>
                                <div className="row listingforrentlistcustom-row-1 listingforrentlistcustom-row-mobile">
                                    <span className="text-secondary fs-6">
                                        Borem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                                        Class aptent taciti sociosqu ad lit...
                                    </span>
                                </div>
                            </div>

                        </div>
                        <hr className="mt-0" />

                        <div className="row">

                            <div className="col-lg-5 col-md-5 col-sm-12">
                                <div className="row justify-content-between">
                                    <img src={img1} className="listingforrentlist-small-div" alt="" />
                                    <img src={img2} className="listingforrentlist-small-div" alt="" />
                                    <img src={img3} className="listingforrentlist-small-div" alt="" />
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-7 col-sm-12 heightAuto">
                                <div className="row w-100 text-secondary fs-6">
                                    <div className="col-auto">
                                        Added on 16/10/2023 by <span className="fw-medium"><u>John D Wood & Co</u></span>
                                    </div>
                                </div>
                                <div className="row w-100 fs-6 mt-1 mb-3">
                                    <div className="col-lg-4 col-md-5 col-sm-12">
                                        <img src={brandLogo} className="listingforrentlist-brand-logo" alt="" />
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-4 ms-auto">
                                        <img src={messageIcon} className="listingforrentlist-icon-message me-2" alt="" />
                                        <span><u>Message</u></span>
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-sm-4">
                                        <img src={callIcon} className="listingforrentlist-icon-call me-2" alt="" />
                                        <span><u>Call</u></span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-12 listingforrentlist-main-div mt-4">
                        <div className="row">

                            <div className="col-lg-5 col-md-5 col-sm-12">
                                <img src={mainImg} className="listingforrentlist-img" alt="" />
                            </div>

                            <div className="col-lg-7 col-md-7 col-sm-12 heightAuto">
                                <div className="row w-100 justify-content-between mt-2 listingforrentlistcustom-row-mobile">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <label htmlFor="" className="listingforrentlist-label fs-6">New Home</label>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end align-items-center">
                                        <img src={save} className="listingforrentlist-save-icon" alt="" />
                                        <input type="button" className="listingforrentlist-btn fw-medium fs-6" value="Save" />
                                    </div>
                                </div>
                                <div className="row w-100 mt-3 listingforrentlistcustom-row-mobile">
                                    <span className="fw-medium fs-6">4 bed detached house for rent</span>
                                </div>
                                <div className="row w-100 justify-content-between mt-3 listingforrentlistcustom-row-mobile">
                                    <div className="col-auto">
                                        <span className="text-secondary fs-6">Knights Road, Oxford OX4</span>
                                    </div>
                                    <div className="col-auto">
                                        <span className="text-secondary fs-6">Guide Price</span>
                                    </div>
                                </div>
                                <div className="row w-100 justify-content-between align-items-center mt-3 listingforrentlistcustom-row-mobile">
                                    <div className="col-auto">
                                        <div className="row">
                                            <div className="col-auto">
                                                <p className="text-secondary">
                                                    <img src={bed} className="homecontainer4-icon" alt="" /> <span className="text-dark fs-6">4</span>
                                                </p>
                                            </div>
                                            &bull;
                                            <div className="col-auto">
                                                <p className="text-secondary">
                                                    <img src={bath} className="homecontainer4-icon" alt="" /> <span className="text-dark fs-6">2</span>
                                                </p>
                                            </div>
                                            &bull;
                                            <div className="col-auto">
                                                <p className="text-secondary">
                                                    <span className="text-dark fs-6">5,000</span> <span className="text-secondary">ft²</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <span className="fs-3 fw-bold">£12,300</span>
                                    </div>
                                </div>
                                <div className="row listingforrentlistcustom-row-1 listingforrentlistcustom-row-mobile">
                                    <span className="text-secondary fs-6">
                                        Borem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                                        Class aptent taciti sociosqu ad lit...
                                    </span>
                                </div>
                            </div>

                        </div>
                        <hr className="mt-0" />

                        <div className="row">

                            <div className="col-lg-5 col-md-5 col-sm-12">
                                <div className="row justify-content-between">
                                    <img src={img1} className="listingforrentlist-small-div" alt="" />
                                    <img src={img2} className="listingforrentlist-small-div" alt="" />
                                    <img src={img3} className="listingforrentlist-small-div" alt="" />
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-7 col-sm-12 heightAuto">
                                <div className="row w-100 text-secondary fs-6">
                                    <div className="col-auto">
                                        Added on 16/10/2023 by <span className="fw-medium"><u>John D Wood & Co</u></span>
                                    </div>
                                </div>
                                <div className="row w-100 fs-6 mt-1 mb-3">
                                    <div className="col-lg-4 col-md-5 col-sm-12">
                                        <img src={brandLogo} className="listingforrentlist-brand-logo" alt="" />
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-4 ms-auto">
                                        <img src={messageIcon} className="listingforrentlist-icon-message me-2" alt="" />
                                        <span><u>Message</u></span>
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-sm-4">
                                        <img src={callIcon} className="listingforrentlist-icon-call me-2" alt="" />
                                        <span><u>Call</u></span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-12 listingforrentlist-main-div mt-4">
                        <div className="row">

                            <div className="col-lg-5 col-md-5 col-sm-12">
                                <img src={mainImg} className="listingforrentlist-img" alt="" />
                            </div>

                            <div className="col-lg-7 col-md-7 col-sm-12 heightAuto">
                                <div className="row w-100 justify-content-end mt-2 listingforrentlistcustom-row-mobile">
                                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end align-items-center">
                                        <img src={save} className="listingforrentlist-save-icon" alt="" />
                                        <input type="button" className="listingforrentlist-btn fw-medium fs-6" value="Save" />
                                    </div>
                                </div>
                                <div className="row w-100 mt-3 listingforrentlistcustom-row-mobile">
                                    <span className="fw-medium fs-6">4 bed detached house for rent</span>
                                </div>
                                <div className="row w-100 justify-content-between mt-3 listingforrentlistcustom-row-mobile">
                                    <div className="col-auto">
                                        <span className="text-secondary fs-6">Knights Road, Oxford OX4</span>
                                    </div>
                                    <div className="col-auto">
                                        <span className="text-secondary fs-6">Guide Price</span>
                                    </div>
                                </div>
                                <div className="row w-100 justify-content-between align-items-center mt-3 listingforrentlistcustom-row-mobile">
                                    <div className="col-auto">
                                        <div className="row">
                                            <div className="col-auto">
                                                <p className="text-secondary">
                                                    <img src={bed} className="homecontainer4-icon" alt="" /> <span className="text-dark fs-6">4</span>
                                                </p>
                                            </div>
                                            &bull;
                                            <div className="col-auto">
                                                <p className="text-secondary">
                                                    <img src={bath} className="homecontainer4-icon" alt="" /> <span className="text-dark fs-6">2</span>
                                                </p>
                                            </div>
                                            &bull;
                                            <div className="col-auto">
                                                <p className="text-secondary">
                                                    <span className="text-dark fs-6">5,000</span> <span className="text-secondary">ft²</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <span className="fs-3 fw-bold">£12,300</span>
                                    </div>
                                </div>
                                <div className="row listingforrentlistcustom-row-1 listingforrentlistcustom-row-mobile">
                                    <span className="text-secondary fs-6">
                                        Borem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                                        Class aptent taciti sociosqu ad lit...
                                    </span>
                                </div>
                            </div>

                        </div>
                        <hr className="mt-0" />

                        <div className="row">

                            <div className="col-lg-5 col-md-5 col-sm-12">
                                <div className="row justify-content-between">
                                    <img src={img1} className="listingforrentlist-small-div" alt="" />
                                    <img src={img2} className="listingforrentlist-small-div" alt="" />
                                    <img src={img3} className="listingforrentlist-small-div" alt="" />
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-7 col-sm-12 heightAuto">
                                <div className="row w-100 text-secondary fs-6">
                                    <div className="col-auto">
                                        Added on 16/10/2023 by <span className="fw-medium"><u>John D Wood & Co</u></span>
                                    </div>
                                </div>
                                <div className="row w-100 fs-6 mt-1 mb-3">
                                    <div className="col-lg-4 col-md-5 col-sm-12">
                                        <img src={brandLogo} className="listingforrentlist-brand-logo" alt="" />
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-4 ms-auto">
                                        <img src={messageIcon} className="listingforrentlist-icon-message me-2" alt="" />
                                        <span><u>Message</u></span>
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-sm-4">
                                        <img src={callIcon} className="listingforrentlist-icon-call me-2" alt="" />
                                        <span><u>Call</u></span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-12 listingforrentlist-main-div mt-4">
                        <div className="row">

                            <div className="col-lg-5 col-md-5 col-sm-12">
                                <img src={mainImg} className="listingforrentlist-img" alt="" />
                            </div>

                            <div className="col-lg-7 col-md-7 col-sm-12 heightAuto">
                                <div className="row w-100 justify-content-between mt-2 listingforrentlistcustom-row-mobile">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <label htmlFor="" className="listingforrentlist-label fs-6">New Home</label>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end align-items-center">
                                        <img src={save} className="listingforrentlist-save-icon" alt="" />
                                        <input type="button" className="listingforrentlist-btn fw-medium fs-6" value="Save" />
                                    </div>
                                </div>
                                <div className="row w-100 mt-3 listingforrentlistcustom-row-mobile">
                                    <span className="fw-medium fs-6">4 bed detached house for rent</span>
                                </div>
                                <div className="row w-100 justify-content-between mt-3 listingforrentlistcustom-row-mobile">
                                    <div className="col-auto">
                                        <span className="text-secondary fs-6">Knights Road, Oxford OX4</span>
                                    </div>
                                    <div className="col-auto">
                                        <span className="text-secondary fs-6">Guide Price</span>
                                    </div>
                                </div>
                                <div className="row w-100 justify-content-between align-items-center mt-3 listingforrentlistcustom-row-mobile">
                                    <div className="col-auto">
                                        <div className="row">
                                            <div className="col-auto">
                                                <p className="text-secondary">
                                                    <img src={bed} className="homecontainer4-icon" alt="" /> <span className="text-dark fs-6">4</span>
                                                </p>
                                            </div>
                                            &bull;
                                            <div className="col-auto">
                                                <p className="text-secondary">
                                                    <img src={bath} className="homecontainer4-icon" alt="" /> <span className="text-dark fs-6">2</span>
                                                </p>
                                            </div>
                                            &bull;
                                            <div className="col-auto">
                                                <p className="text-secondary">
                                                    <span className="text-dark fs-6">5,000</span> <span className="text-secondary">ft²</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <span className="fs-3 fw-bold">£12,300</span>
                                    </div>
                                </div>
                                <div className="row listingforrentlistcustom-row-1 listingforrentlistcustom-row-mobile">
                                    <span className="text-secondary fs-6">
                                        Borem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                                        Class aptent taciti sociosqu ad lit...
                                    </span>
                                </div>
                            </div>

                        </div>
                        <hr className="mt-0" />

                        <div className="row">

                            <div className="col-lg-5 col-md-5 col-sm-12">
                                <div className="row justify-content-between">
                                    <img src={img1} className="listingforrentlist-small-div" alt="" />
                                    <img src={img2} className="listingforrentlist-small-div" alt="" />
                                    <img src={img3} className="listingforrentlist-small-div" alt="" />
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-7 col-sm-12 heightAuto">
                                <div className="row w-100 text-secondary fs-6">
                                    <div className="col-auto">
                                        Added on 16/10/2023 by <span className="fw-medium"><u>John D Wood & Co</u></span>
                                    </div>
                                </div>
                                <div className="row w-100 fs-6 mt-1 mb-3">
                                    <div className="col-lg-4 col-md-5 col-sm-12">
                                        <img src={brandLogo} className="listingforrentlist-brand-logo" alt="" />
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-4 ms-auto">
                                        <img src={messageIcon} className="listingforrentlist-icon-message me-2" alt="" />
                                        <span><u>Message</u></span>
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-sm-4">
                                        <img src={callIcon} className="listingforrentlist-icon-call me-2" alt="" />
                                        <span><u>Call</u></span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-12 listingforrentlist-main-div mt-4">
                        <div className="row">

                            <div className="col-lg-5 col-md-5 col-sm-12">
                                <img src={mainImg} className="listingforrentlist-img" alt="" />
                            </div>

                            <div className="col-lg-7 col-md-7 col-sm-12 heightAuto">
                                <div className="row w-100 justify-content-end mt-2 listingforrentlistcustom-row-mobile">
                                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end align-items-center">
                                        <img src={save} className="listingforrentlist-save-icon" alt="" />
                                        <input type="button" className="listingforrentlist-btn fw-medium fs-6" value="Save" />
                                    </div>
                                </div>
                                <div className="row w-100 mt-3 listingforrentlistcustom-row-mobile">
                                    <span className="fw-medium fs-6">4 bed detached house for rent</span>
                                </div>
                                <div className="row w-100 justify-content-between mt-3 listingforrentlistcustom-row-mobile">
                                    <div className="col-auto">
                                        <span className="text-secondary fs-6">Knights Road, Oxford OX4</span>
                                    </div>
                                    <div className="col-auto">
                                        <span className="text-secondary fs-6">Guide Price</span>
                                    </div>
                                </div>
                                <div className="row w-100 justify-content-between align-items-center mt-3 listingforrentlistcustom-row-mobile">
                                    <div className="col-auto">
                                        <div className="row">
                                            <div className="col-auto">
                                                <p className="text-secondary">
                                                    <img src={bed} className="homecontainer4-icon" alt="" /> <span className="text-dark fs-6">4</span>
                                                </p>
                                            </div>
                                            &bull;
                                            <div className="col-auto">
                                                <p className="text-secondary">
                                                    <img src={bath} className="homecontainer4-icon" alt="" /> <span className="text-dark fs-6">2</span>
                                                </p>
                                            </div>
                                            &bull;
                                            <div className="col-auto">
                                                <p className="text-secondary">
                                                    <span className="text-dark fs-6">5,000</span> <span className="text-secondary">ft²</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <span className="fs-3 fw-bold">£12,300</span>
                                    </div>
                                </div>
                                <div className="row listingforrentlistcustom-row-1 listingforrentlistcustom-row-mobile">
                                    <span className="text-secondary fs-6">
                                        Borem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                                        Class aptent taciti sociosqu ad lit...
                                    </span>
                                </div>
                            </div>

                        </div>
                        <hr className="mt-0" />

                        <div className="row">

                            <div className="col-lg-5 col-md-5 col-sm-12">
                                <div className="row justify-content-between">
                                    <img src={img1} className="listingforrentlist-small-div" alt="" />
                                    <img src={img2} className="listingforrentlist-small-div" alt="" />
                                    <img src={img3} className="listingforrentlist-small-div" alt="" />
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-7 col-sm-12 heightAuto">
                                <div className="row w-100 text-secondary fs-6">
                                    <div className="col-auto">
                                        Added on 16/10/2023 by <span className="fw-medium"><u>John D Wood & Co</u></span>
                                    </div>
                                </div>
                                <div className="row w-100 fs-6 mt-1 mb-3">
                                    <div className="col-lg-4 col-md-5 col-sm-12">
                                        <img src={brandLogo} className="listingforrentlist-brand-logo" alt="" />
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-4 ms-auto">
                                        <img src={messageIcon} className="listingforrentlist-icon-message me-2" alt="" />
                                        <span><u>Message</u></span>
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-sm-4">
                                        <img src={callIcon} className="listingforrentlist-icon-call me-2" alt="" />
                                        <span><u>Call</u></span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-12 listingforrentlist-main-div mt-4">
                        <div className="row">

                            <div className="col-lg-5 col-md-5 col-sm-12">
                                <img src={mainImg} className="listingforrentlist-img" alt="" />
                            </div>

                            <div className="col-lg-7 col-md-7 col-sm-12 heightAuto">
                                <div className="row w-100 justify-content-between mt-2 listingforrentlistcustom-row-mobile">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <label htmlFor="" className="listingforrentlist-label fs-6">New Home</label>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end align-items-center">
                                        <img src={save} className="listingforrentlist-save-icon" alt="" />
                                        <input type="button" className="listingforrentlist-btn fw-medium fs-6" value="Save" />
                                    </div>
                                </div>
                                <div className="row w-100 mt-3 listingforrentlistcustom-row-mobile">
                                    <span className="fw-medium fs-6">4 bed detached house for rent</span>
                                </div>
                                <div className="row w-100 justify-content-between mt-3 listingforrentlistcustom-row-mobile">
                                    <div className="col-auto">
                                        <span className="text-secondary fs-6">Knights Road, Oxford OX4</span>
                                    </div>
                                    <div className="col-auto">
                                        <span className="text-secondary fs-6">Guide Price</span>
                                    </div>
                                </div>
                                <div className="row w-100 justify-content-between align-items-center mt-3 listingforrentlistcustom-row-mobile">
                                    <div className="col-auto">
                                        <div className="row">
                                            <div className="col-auto">
                                                <p className="text-secondary">
                                                    <img src={bed} className="homecontainer4-icon" alt="" /> <span className="text-dark fs-6">4</span>
                                                </p>
                                            </div>
                                            &bull;
                                            <div className="col-auto">
                                                <p className="text-secondary">
                                                    <img src={bath} className="homecontainer4-icon" alt="" /> <span className="text-dark fs-6">2</span>
                                                </p>
                                            </div>
                                            &bull;
                                            <div className="col-auto">
                                                <p className="text-secondary">
                                                    <span className="text-dark fs-6">5,000</span> <span className="text-secondary">ft²</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <span className="fs-3 fw-bold">£12,300</span>
                                    </div>
                                </div>
                                <div className="row listingforrentlistcustom-row-1 listingforrentlistcustom-row-mobile">
                                    <span className="text-secondary fs-6">
                                        Borem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                                        Class aptent taciti sociosqu ad lit...
                                    </span>
                                </div>
                            </div>

                        </div>
                        <hr className="mt-0" />

                        <div className="row">

                            <div className="col-lg-5 col-md-5 col-sm-12">
                                <div className="row justify-content-between">
                                    <img src={img1} className="listingforrentlist-small-div" alt="" />
                                    <img src={img2} className="listingforrentlist-small-div" alt="" />
                                    <img src={img3} className="listingforrentlist-small-div" alt="" />
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-7 col-sm-12 heightAuto">
                                <div className="row w-100 text-secondary fs-6">
                                    <div className="col-auto">
                                        Added on 16/10/2023 by <span className="fw-medium"><u>John D Wood & Co</u></span>
                                    </div>
                                </div>
                                <div className="row w-100 fs-6 mt-1 mb-3">
                                    <div className="col-lg-4 col-md-5 col-sm-12">
                                        <img src={brandLogo} className="listingforrentlist-brand-logo" alt="" />
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-4 ms-auto">
                                        <img src={messageIcon} className="listingforrentlist-icon-message me-2" alt="" />
                                        <span><u>Message</u></span>
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-sm-4">
                                        <img src={callIcon} className="listingforrentlist-icon-call me-2" alt="" />
                                        <span><u>Call</u></span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="row mt-5 mb-5 fw-medium">
                        <nav aria-label="Page navigation" className="d-flex justify-content-center">
                            <ul className="pagination text-dark">
                                <li className="page-item"><a className="page-link" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" stroke="currentColor" className="bi bi-chevron-left me-1 mb-1" viewBox="0 0 16 16">
                                    <linearGradient id="PaginationGradient" x1="0" y1="0" x2="1" y2="0">
                                        <stop offset="0%" stopColor="rgb(162, 139, 250)" />
                                        <stop offset="52.08%" stopColor="rgb(206, 112, 193)" />
                                        <stop offset="100%" stopColor="rgb(213, 107, 112)" />
                                    </linearGradient>
                                    <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0" />
                                </svg><span className="text-dark">Prev</span></a></li>
                                <li className="page-item"><a className="page-link text-dark active" href="#">1</a></li>
                                <li className="page-item"><a className="page-link text-dark" href="#">2</a></li>
                                <li className="page-item"><a className="page-link text-dark" href="#">3</a></li>
                                <li className="page-item"><a className="page-link text-dark" href="#">4</a></li>
                                <li className="page-item"><a className="page-link text-dark" href="#">5</a></li>
                                <li className="page-item"><a className="page-link text-dark" href="#">...</a></li>
                                <li className="page-item"><a className="page-link text-dark" href="#">15</a></li>
                                <li className="page-item"><a className="page-link text-dark" href="#"><span className="me-2">Next</span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 0 .708L10.293 8l-5.647 5.646a.5.5 0 1 1-.708-.708l6-6a.5.5 0 0 1 0-.708l-6-6a.5.5 0 0 1 .708 0" />
                                </svg></a></li>
                            </ul>
                        </nav>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ListingForRentList;