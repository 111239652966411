import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Footer.css';
import footerSocial1 from '../../Assets/Images/Footer/footer-social-1.png';
import footerSocial2 from '../../Assets/Images/Footer/footer-social-2.png';
import footerSocial3 from '../../Assets/Images/Footer/footer-social-3.png';
import footerSocial4 from '../../Assets/Images/Footer/footer-social-4.png';
import footerSocial5 from '../../Assets/Images/Footer/footer-social-5.png';
import footerSocial6 from '../../Assets/Images/Footer/footer-social-6.png';
import footerLogo from '../../Assets/Images/Logo.png';

function Footer() {
  return (
    <footer className="footer">
      <div className="container-fluid footer-container">
        <div className="row justify-content-center">

          <div className="col-10 col-lg-8 mt-4 justify-content-center">

            <div className="row justify-content-between footercustomwidthmobile-2">
              <div className="col-sm-12 col-lg-4 col-md-4 mt-4">
                <a className="navbar-brand" href="/homepage">
                  <img src={footerLogo} alt="Logo" className="header-logo" />
                </a>
                <p className="footer-p mt-3">
                  Our objective is to assist individuals who are in a vulnerable state be it physically, emotionally,
                  spiritually, or mentally by either connecting them with the necessary resources or providing direct
                  support to ensure their successful recovery and overcoming of obstacles.
                </p>
                <p className="footer-p-2 text-white">Follow Us on Social Media</p>
                <div>
                  <a className="footer-social" href="https://www.instagram.com/paperplusmedia/">
                    <img
                      className="footer-social"
                      alt="instagram logo"
                      src={footerSocial1}
                    />
                  </a>
                  <a className="footer-social" href="https://www.facebook.com/ppplusmedia/">
                    <img
                      className="footer-social"
                      alt="facebook logo"
                      src={footerSocial2}
                    />
                  </a>
                  <a className="footer-social" href="https://www.threads.net/@paperplusmedia">
                    <img
                      className="footer-social"
                      alt="threads logo"
                      src={footerSocial3}
                    />
                  </a>
                  <a className="footer-social" href="https://www.youtube.com/channel/UCwuwOGCwYJxsesM4o19nf9A">
                    <img
                      className="footer-social"
                      alt="youtube logo"
                      src={footerSocial4}
                    />
                  </a>
                  <a className="footer-social" href="https://twitter.com/paperplusmedia">
                    <img
                      className="footer-social"
                      alt="X logo"
                      src={footerSocial5}
                    />
                  </a>
                  <a className="footer-social" href="https://www.linkedin.com/in/paper-plus-media-98b144289/">
                    <img
                      className="footer-social"
                      alt="linkedIn logo"
                      src={footerSocial6}
                    />
                  </a>
                </div>
              </div>

              <div className="col-lg-5 col-md-5 mt-4">
                <div className="row">
                  <div className="footer-quick-links col-6 footercustomwidthmobile-1">
                    <div className="footer-link"><a className="footer-links" href="#">Properties for Sale</a><br /></div>
                    <div className="footer-link mt-4"><a className="footer-links" href="#">Properties for Rent</a><br /></div>
                    <div className="footer-link mt-4"><a className="footer-links" href="#">Sell a Property</a><br /></div>
                    <div className="footer-link mt-4"><a className="footer-links" href="#">Find Agents</a><br /></div>
                    <div className="footer-link mt-4"><a className="footer-links" href="#">Get a Valuation</a></div>
                    <div className="footer-link mt-4"><a className="footer-links" href="/advertise">Advertise with us</a></div>
                  </div>

                  <div className="col-auto footercustomwidthmobile-1">
                    <div className="footer-link"><a className="footer-links" href="#">About Us</a><br /></div>
                    <div className="footer-link mt-4"><a className="footer-links" href="/contact-us">Contact Us</a><br /></div>
                    <div className="footer-link mt-4"><a className="footer-links" href="/faqs">FAQ</a><br /></div>
                    <div className="footer-link mt-4"><a className="footer-links" href="#">Privacy Policy</a><br /></div>
                    <div className="footer-link mt-4"><a className="footer-links" href="#">Term & Condition</a></div>
                    <div className="footer-link mt-4"><a className="footer-links" href="#">GDPR</a></div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 mt-4">
                <label className="footer-header" htmlFor="">Get Subscribed</label>
                <p className="footer-p mt-3">
                  Subscribe our blog for getting latest news and updates of our activity
                </p>
                <input className="footer-email text-secondary ps-2" type="email" name="" id="" placeholder="Enter Email Address" />
                <input className="footer-subscribe mt-3" type="submit" value="Subscribe" />
              </div>
            </div>

            <div className="row justify-content-center mt-5">
              <hr className="footer-bottom-hr" />
              <p className="footer-bottom-text">Paper Plus Media Limited © 2023-2024. All Rights Reserved.</p>
            </div>

          </div>

        </div>
      </div>
    </footer>
  )
}

export default Footer;