import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './FirmInfoSellerDetails.css';
import firmInfoSellerDetailsImg1 from '../../Assets/Images/FirmInfo/firminfosellerdetails-img-1.png';
import firmInfoSellerDetailsImg2 from '../../Assets/Images/FirmInfo/firminfosellerdetails-img-2.png';
import firmInfoSellerDetailsImg3 from '../../Assets/Images/FirmInfo/firminfosellerdetails-img-3.png';
import firmInfoSellerDetailsImg4 from '../../Assets/Images/FirmInfo/firminfosellerdetails-img-4.png';
import firmInfoSellerDetailsImg5 from '../../Assets/Images/FirmInfo/firminfosellerdetails-img-5.png';
import firmInfoSellerDetailsImg6 from '../../Assets/Images/FirmInfo/firminfosellerdetails-img-6.png';
import firmInfoSellerDetailsImg7 from '../../Assets/Images/FirmInfo/firminfosellerdetails-img-7.png';

function FirmInfoSellerDetails() {
    return (
        <div className="firminfosellerdetails">
            <div className="container-fluid firminfosellerdetails-container">
                <div className="col-12 p-3">

                    <div className="row">
                        <h2>Get in touch</h2>
                    </div>

                    <div className="row mt-5">
                        <div className="col-2">
                            <img src={firmInfoSellerDetailsImg1} className="firminfosellerdetails-img" alt="" />
                        </div>
                        <div className="col-10">
                            <div className="row">
                                <h3>Address</h3>
                                <span className="mt-2">Ashville Business Park</span>
                                <span>Cheltenham Road East</span>
                                <span>Gloucester</span>
                                <span>Gloucestershire</span>
                                <span><strong>BD21 1QD</strong></span>
                                <span className="firminfosellerdetails-gray">4.3 miles from you</span>
                                <span className="firminfosellerdetails-gradient">Get Directions</span>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-2">
                            <img src={firmInfoSellerDetailsImg2} className="firminfosellerdetails-img" alt="" />
                        </div>
                        <div className="col-10">
                            <div className="row">
                                <h3>Phone</h3>
                                <span className="firminfosellerdetails-gradient">(01908) 942082</span>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-2">
                            <img src={firmInfoSellerDetailsImg3} className="firminfosellerdetails-img" alt="" />
                        </div>
                        <div className="col-10">
                            <div className="row">
                                <h3>Website</h3>
                                <span className="firminfosellerdetails-gradient">www.examplesite.co.uk</span>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-2">
                            <img src={firmInfoSellerDetailsImg1} className="firminfosellerdetails-img" alt="" />
                        </div>
                        <div className="col-10">
                            <div className="row align-items-center">
                                <h3>Social Media</h3>
                                <div className="col-2">
                                    <img src={firmInfoSellerDetailsImg5} className="firminfosellerdetails-img" alt="" />
                                </div>
                                <div className="col-2">
                                    <img src={firmInfoSellerDetailsImg6} className="firminfosellerdetails-img" alt="" />
                                </div>
                                <div className="col-2">
                                    <img src={firmInfoSellerDetailsImg7} className="firminfosellerdetails-img" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-2">
                            <img src={firmInfoSellerDetailsImg4} className="firminfosellerdetails-img" alt="" />
                        </div>
                        <div className="col-10">
                            <div className="row">
                                <h3>Opening Hours</h3>
                            </div>
                            <div className="row w-100 d-flex justify-content-between">
                                <div>
                                    <span className="">Monday</span>
                                    <span className="ms-5">08:30 - 18:00</span>
                                </div>
                                <div>
                                    <span className="">Monday</span>
                                    <span className="ms-5">08:30 - 18:00</span>
                                </div>
                                <div>
                                    <span className="">Monday</span>
                                    <span className="ms-5">08:30 - 18:00</span>
                                </div>
                                <div>
                                    <span className="">Monday</span>
                                    <span className="ms-5">08:30 - 18:00</span>
                                </div>
                                <div>
                                    <span className="">Monday</span>
                                    <span className="ms-5">08:30 - 18:00</span>
                                </div>
                                <div>
                                    <span className="">Monday</span>
                                    <span className="ms-5">08:30 - 18:00</span>
                                </div>
                                <div>
                                    <span className="">Monday</span>
                                    <span className="ms-5">08:30 - 18:00</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default FirmInfoSellerDetails;