import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from "../Components/Layout/Layout";
import Accordion from 'react-bootstrap/Accordion';
import IconGlobe from "../Assets/Icons/IconGlobe";
import overseasThumbnail from '../Assets/Images/overseas-thumbnail.jpg'; // Fallback image

// Mock data for countries
const countries = [
  { name: "Spain", image: overseasThumbnail },
  { name: "France", image: overseasThumbnail },
  { name: "Portugal", image: overseasThumbnail },
  { name: "Cyprus", image: overseasThumbnail },
  { name: "Italy", image: overseasThumbnail },
  { name: "Greece", image: overseasThumbnail },
  { name: "USA", image: overseasThumbnail },
  { name: "Ireland", image: overseasThumbnail },
  { name: "Bulgaria", image: overseasThumbnail },
  { name: "Turkey", image: overseasThumbnail },
  { name: "Germany", image: overseasThumbnail },
  { name: "Thailand", image: overseasThumbnail }
];

// Mock data for countries by region
const countriesByRegion = {
  Europe: ["Spain", "France", "Portugal", "Cyprus", "Italy", "Greece"],
  Americas: ["USA", "Canada", "Brazil", "Mexico", "Argentina"],
  Africa: ["South Africa", "Egypt", "Morocco", "Nigeria", "Kenya"],
  Asia: ["China", "Japan", "India", "Thailand", "Malaysia"],
  Caribbean: ["Bahamas", "Jamaica", "Barbados", "Trinidad and Tobago"],
  Oceania: ["Australia", "New Zealand", "Fiji", "Papua New Guinea"]
};

const Overseas = () => {
  const [regions, setRegions] = useState(countriesByRegion);

  useEffect(() => {
    // Mock data is already set, so no API call needed here for now
  }, []);

  return (
    <Layout>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="overseashero-container"></div>
          <div className="row justify-content-center">
            <div className="col-10 col-lg-6">
              <div className="overseasoverlay border-0 p-4">
                <div className="row">
                  <h3 className="text-center fw-bold text-gradient">Believe in finding it overseas</h3>
                </div>
                <div className="row mt-2">
                  <h5 className="text-center text-white">Search properties for sale worldwide</h5>
                </div>
                <div className="row mt-2">
                  <div className="col-7 col-md-10 col-lg-10">
                    <input type="text" className="db-input-settings form-control fw-medium" placeholder="e.g.'Madrid', 'Paris', 'Spain', 'France'" />
                  </div>
                  <div className="col-5 col-md-2 col-lg-2">
                    <input type="button" value="For Sale" className="form-control db-input-settings fw-medium btn-primary text-white" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-10 col-lg-8">
            <div className="row justify-content-between d-flex mt-5">
              <div className="col-auto"><h5 className="text-secondary">Most popular countries</h5></div>
              <div className="col-auto"><span className="ms-auto fw-medium">Buying overseas safely</span></div>
            </div>
            <div className="row">
              {countries.map((country, index) => (
                <div className="col-6 col-md-4 col-lg-2 mb-4" key={index}>
                  <div className="overseasthumbnail text-center p-0">
                    <img src={country.image} className="w-100 overseas-thumbnail-img" alt={country.name} />
                    <div className="row mt-2 pb-2">
                      <span className="fw-medium">{country.name}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="row mt-5">
              <div className="row">
                <span className="text-secondary align-items-center"><IconGlobe /><span className="h5 ms-1">Search Overseas Property for sale in:</span></span>
              </div>
              <Accordion className="mt-3" id="overseasAccordion">
                {Object.entries(regions).map(([region, countries], index) => (
                  <Accordion.Item eventKey={index.toString()} key={region} className="mb-3 custom-accordion-item fw-medium text-secondary">
                    <Accordion.Header className="fw-bold">{region}</Accordion.Header>
                    <Accordion.Body>
                      <div className="row">
                        {countries.map((country, idx) => (
                          <div className="col-4 mt-3" key={idx}>{country}</div>
                        ))}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Overseas;
