import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './HomeContainer2.css';

function HomeContainer2() {

    const [selectedOption, setSelectedOption] = useState("For Sale");

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
    return (
        <div className="homecontainer2">
            <div className="container-fluid homecontainer2-container">
                <div className="row justify-content-center">

                    <div className="row homecontainer2-hero-margin-top">
                        <h1 className="text-white text-center display-3 fw-medium">Lorem ipsum dolor sit amet.</h1>
                        <h4 className="text-white text-center">Search properties for sale and to rent in the UK</h4>
                    </div>

                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-6 col-md-10 col-sm-12">
                    <div className="homecontainer2overlay p-4">
                        <div className="row">
                            <div className="col-auto homecontainer2-btn-container">
                                <input
                                    type="radio"
                                    className="btn-check"
                                    id="for-sale-btn"
                                    autoComplete="off"
                                    value="For Sale"
                                    checked={selectedOption === 'For Sale'}
                                    onChange={handleOptionChange}
                                />
                                <label
                                    className={`btn btn-dark homecontainer2-for-sale-btn text-dark fw-medium ${selectedOption === 'For Sale' ? 'selected' : ''
                                        }`}
                                    htmlFor="for-sale-btn"
                                >
                                    For Sale
                                </label>

                                <input
                                    type="radio"
                                    className="btn-check"
                                    id="for-rent-btn"
                                    autoComplete="off"
                                    value="For Rent"
                                    checked={selectedOption === 'For Rent'}
                                    onChange={handleOptionChange}
                                />
                                <label
                                    className={`btn btn-dark homecontainer2-for-rent-btn text-dark fw-medium ${selectedOption === 'For Rent' ? 'selected' : ''
                                        }`}
                                    htmlFor="for-rent-btn"
                                >
                                    Rent
                                </label>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <span className="fw-medium fs-6">Enter Location</span>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-10 col-md-10 col-sm-12">
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control text-secondary fw-medium" aria-label="Text input with dropdown button" placeholder="e.g. ‘Oxford’, ‘NW3’, or ‘Waterloo Station" />
                                    <button className="btn btn-light dropdown-toggle fw-normal homecontainer2overlay-height-1 homecontainer2overlay-dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span className="me-3">This Area Only</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                            <linearGradient id="DropdownGradient" x1="0" y1="0" x2="1" y2="0">
                                                <stop offset="0%" stopColor="rgb(162, 139, 250)" />
                                                <stop offset="52.08%" stopColor="rgb(206, 112, 193)" />
                                                <stop offset="100%" stopColor="rgb(213, 107, 112)" />
                                            </linearGradient>
                                            <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                                        </svg>
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end">
                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-12">
                                <input type="button" className="btn w-100 homeoverlay-height-1 homecontainer2overlay-btn btn-dark" value="Search" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeContainer2;