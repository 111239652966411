import React from "react";
import './HeroPage.css';

function HeroPage({ title, subtitle, backgroundImage }) {
  return (
    <div className="heroPage" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.6)), url(${backgroundImage})`, backgroundPosition: 'center center', backgroundSize: 'cover' }}>
      <div className="container-fluid heroPage-container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-12 col-sm-12">
            <div className="row heroPage-hero-margin-top">
              <h1 className="text-white text-center display-6 fw-medium">{title}</h1>
              <h1 className="text-white text-center display-3 fw-medium">{subtitle}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroPage;
