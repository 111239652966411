import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";

const Advertise = () => {
  return (
    <div className="container-fluid">

      <Header />

      <div className="container-box">
        <div className="row justify-content-center">
          <div className="col-11 col-lg-8">

            <div className="row text-center mt-5 mb-5">
              <h1 className="display-3 text-gradient">Advertise with us</h1>
            </div>

            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 bg-advertise">
                <p className="p-5 fs-3 text-white">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Quo impedit at amet, esse aliquid adipisci fugiat quod
                  qui earum cumque!
                </p>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div className="row text-center">
                  <h3 className="fw-medium">Get in touch today</h3>
                  <h5 className="fw-normal">
                    Please call xxxx xxx xxxx or complete the form below.
                  </h5>
                  <p className="text-dark">
                    *Required fields
                  </p>
                </div>
                <div className="row mt-3">
                  <div className="col-6">
                    <div className="mb-3">
                      <label className="form-label fw-medium"><small>First name<span className="text-danger">*</span></small></label>
                      <input type="text" className="form-control form-control-2 fw-medium" placeholder="" />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="mb-3">
                      <label className="form-label fw-medium"><small>Last name<span className="text-danger">*</span></small></label>
                      <input type="text" className="form-control form-control-2 fw-medium" placeholder="" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3">
                      <label className="form-label fw-medium"><small>Company<span className="text-danger">*</span></small></label>
                      <input type="text" className="form-control form-control-2 fw-medium" placeholder="" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="mb-3">
                      <label className="form-label fw-medium"><small>Email Address<span className="text-danger">*</span></small></label>
                      <input type="email" className="form-control form-control-2 fw-medium" placeholder="" />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="mb-3">
                      <label className="form-label fw-medium"><small>Phone Number<span className="text-danger">*</span></small></label>
                      <input type="text" className="form-control form-control-2 fw-medium" placeholder="" />
                    </div>
                  </div>
                </div>
                <div className="row d-flex">
                  <div className="col-auto">
                    <div className="custom-checkbox-outline mt-1">
                      <input className="fs-5 custom-checkbox top-50 start-50 translate-middle" type="checkbox" id="advertiseCheckbox" />
                    </div>
                  </div>
                  <div className="col-11">
                    <p className="fw-medium text-justify">
                      <small>
                        PP+Properties would like to contact you occasionally by email, phone, or text with marketing
                        information about our products and services, including offers and promotions. If you
                        DO NOT wish to receive such communications you can do so by ticking this box, clicking
                        on the unsubscribe link at the end of any marketing email or text, informing a member
                        of our team over the phone or via <span className="cardetailcontainer3-gradient">Contact Us. </span>
                        For more information about what we do with your personal data, see our
                        <span className="cardetailcontainer3-gradient"> Privacy and Policy</span>.
                      </small>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <input className="ms-2 btn btn-primary w-25 btn-custom-2" type="button" value="Submit" />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <Footer />

    </div>
  )
}

export default Advertise;