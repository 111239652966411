import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './ListingAgentContainer3.css';
import ListingAgentContainer3Filter from "../ListingAgentContainer3Filter/ListingAgentContainer3Filter";
import ListingAgentContainer3List from "../ListingAgentContainer3List/ListingAgentContainer3List";

function ListingAgentContainer3() {
    const [sortByOption, setSortByOption] = useState('');

    const handleSortByChange = (event) => {
        setSortByOption(event.target.value);
    };
    return (
        <div className="listingagentcontainer3">
            <div className="container-fluid listingagentcontainer3-container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-sm-12">

                        <div className="row">

                            <div className="col-lg-3">
                                < ListingAgentContainer3Filter />
                            </div>

                            <div className="col-lg-9">
                                <div className="row d-flex justify-content-between customtablet1">
                                    <div className="col-md-5 col-lg-5 col-sm-12 d-flex custommobile1">
                                        <h4>29 properties listed by John D. Wood & Co.</h4>
                                    </div>

                                    <div className="col-md-5 col-lg-5 col-sm-12 d-flex justify-content-end">
                                        <label htmlFor="" className="text-secondary mt-1">Sort by</label>
                                        <select value={sortByOption} onChange={handleSortByChange} className="listingagentcontainer3-dropdown ms-2">
                                            <option value="relevanceValue">Publish Date</option>
                                            <option value="distanceValue">Distance</option>
                                            <option value="priceLowestValue">Price (Lowest)</option>
                                            <option value="priceHighestValue">Price (Highest)</option>
                                        </select>
                                    </div>
                                </div>
                                <hr className="mt-4" />
                                <ListingAgentContainer3List />
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ListingAgentContainer3;