import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './PropertyDetailFirmContainer4.css';
import propertyDetailContainer4Img1 from '../../Assets/Images/PropertyDetail/propertydetailcontainer4-img-1.png';
import bed from '../../Assets/Images/PropertyDetail/propertydetailcontainer4-icon-bed.png';
import bath from '../../Assets/Images/PropertyDetail/propertydetailcontainer4-icon-bath.png';
import brand from '../../Assets/Images/PropertyDetail/propertydetailcontainer4-icon-brand.png';


function PropertyDetailFirmContainer4() {
    return (
        <div className="propertydetailfirmcontainer4">
            <div className="container-fluid propertydetailfirmcontainer4-container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-12 col-sm-12">

                        <div className="row mt-5">
                            <h4><span className="propertydetailfirmcontainer4-h4">Other properties from this firm</span> <span className="propertydetailfirmcontainer4-gradient fs-5 fw-normal ms-3">See all</span></h4>
                        </div>

                        <div className="row propertydetailfirmcontainer4-main-row justify-content-between mt-3">

                            <div className="col-lg-3 col-md-6 col-sm-12 propertydetailfirmcontainer4-row-div">
                                <div className="row">
                                    <img src={propertyDetailContainer4Img1} className="propertydetailfirmcontainer4-img" alt="" />
                                </div>
                                <div className="row w-100">
                                    <p className="text-secondary">4 bed detached house for sale</p>
                                </div>
                                <div className="row w-100">
                                    <h5>£1,084,942</h5>
                                </div>
                                <div className="row w-100">
                                    <h6>Hill View, Sandhills, Oxford OX3</h6>
                                </div>
                                <div className="row text-secondary w-75 justify-content-between">
                                    <div className="col-auto">
                                        <p className="text-secondary">
                                            <img src={bed} className="propertydetailfirmcontainer4-icon" alt="" /> <span className="text-dark">4</span>
                                        </p>
                                    </div>
                                    &bull;
                                    <div className="col-auto">
                                        <p className="text-secondary">
                                            <img src={bath} className="propertydetailfirmcontainer4-icon" alt="" /> <span className="text-dark">2</span>
                                        </p>
                                    </div>
                                    &bull;
                                    <div className="col-auto">
                                        <p className="text-secondary">
                                            <span className="text-dark">5,000</span> <span className="text-secondary">ft²</span>
                                        </p>
                                    </div>
                                </div>
                                <hr className="mt-0" />
                                <div className="row w-100 mt-4">
                                    <div className="col-7">
                                        <p className="text-secondary fs-6">Marketed by <u><span className="fw-medium fs-6">John D Wood & Co</span></u></p>
                                    </div>
                                    <div className="col-5">
                                        <img src={brand} className="w-100" alt="" />
                                    </div>
                                </div>
                                <div className="row justify-content-center mt-3 mb-4">
                                    <input type="button" className="propertydetailfirmcontainer4-btn" value="Visit Now" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-12 propertydetailfirmcontainer4-row-div">
                                <div className="row">
                                    <img src={propertyDetailContainer4Img1} className="propertydetailfirmcontainer4-img" alt="" />
                                </div>
                                <div className="row w-100">
                                    <p className="text-secondary">4 bed detached house for sale</p>
                                </div>
                                <div className="row w-100">
                                    <h5>£1,084,942</h5>
                                </div>
                                <div className="row w-100">
                                    <h6>Hill View, Sandhills, Oxford OX3</h6>
                                </div>
                                <div className="row text-secondary w-75 justify-content-between">
                                    <div className="col-auto">
                                        <p className="text-secondary">
                                            <img src={bed} className="propertydetailfirmcontainer4-icon" alt="" /> <span className="text-dark">4</span>
                                        </p>
                                    </div>
                                    &bull;
                                    <div className="col-auto">
                                        <p className="text-secondary">
                                            <img src={bath} className="propertydetailfirmcontainer4-icon" alt="" /> <span className="text-dark">2</span>
                                        </p>
                                    </div>
                                    &bull;
                                    <div className="col-auto">
                                        <p className="text-secondary">
                                            <span className="text-dark">5,000</span> <span className="text-secondary">ft²</span>
                                        </p>
                                    </div>
                                </div>
                                <hr className="mt-0" />
                                <div className="row w-100 mt-4">
                                    <div className="col-7">
                                        <p className="text-secondary fs-6">Marketed by <u><span className="fw-medium fs-6">John D Wood & Co</span></u></p>
                                    </div>
                                    <div className="col-5">
                                        <img src={brand} className="w-100" alt="" />
                                    </div>
                                </div>
                                <div className="row justify-content-center mt-3 mb-4">
                                    <input type="button" className="propertydetailfirmcontainer4-btn" value="Visit Now" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-12 propertydetailfirmcontainer4-row-div">
                                <div className="row">
                                    <img src={propertyDetailContainer4Img1} className="propertydetailfirmcontainer4-img" alt="" />
                                </div>
                                <div className="row w-100">
                                    <p className="text-secondary">4 bed detached house for sale</p>
                                </div>
                                <div className="row w-100">
                                    <h5>£1,084,942</h5>
                                </div>
                                <div className="row w-100">
                                    <h6>Hill View, Sandhills, Oxford OX3</h6>
                                </div>
                                <div className="row text-secondary w-75 justify-content-between">
                                    <div className="col-auto">
                                        <p className="text-secondary">
                                            <img src={bed} className="propertydetailfirmcontainer4-icon" alt="" /> <span className="text-dark">4</span>
                                        </p>
                                    </div>
                                    &bull;
                                    <div className="col-auto">
                                        <p className="text-secondary">
                                            <img src={bath} className="propertydetailfirmcontainer4-icon" alt="" /> <span className="text-dark">2</span>
                                        </p>
                                    </div>
                                    &bull;
                                    <div className="col-auto">
                                        <p className="text-secondary">
                                            <span className="text-dark">5,000</span> <span className="text-secondary">ft²</span>
                                        </p>
                                    </div>
                                </div>
                                <hr className="mt-0" />
                                <div className="row w-100 mt-4">
                                    <div className="col-7">
                                        <p className="text-secondary fs-6">Marketed by <u><span className="fw-medium fs-6">John D Wood & Co</span></u></p>
                                    </div>
                                    <div className="col-5">
                                        <img src={brand} className="w-100" alt="" />
                                    </div>
                                </div>
                                <div className="row justify-content-center mt-3 mb-4">
                                    <input type="button" className="propertydetailfirmcontainer4-btn" value="Visit Now" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-12 propertydetailfirmcontainer4-row-div">
                                <div className="row">
                                    <img src={propertyDetailContainer4Img1} className="propertydetailfirmcontainer4-img" alt="" />
                                </div>
                                <div className="row w-100">
                                    <p className="text-secondary">4 bed detached house for sale</p>
                                </div>
                                <div className="row w-100">
                                    <h5>£1,084,942</h5>
                                </div>
                                <div className="row w-100">
                                    <h6>Hill View, Sandhills, Oxford OX3</h6>
                                </div>
                                <div className="row text-secondary w-75 justify-content-between">
                                    <div className="col-auto">
                                        <p className="text-secondary">
                                            <img src={bed} className="propertydetailfirmcontainer4-icon" alt="" /> <span className="text-dark">4</span>
                                        </p>
                                    </div>
                                    &bull;
                                    <div className="col-auto">
                                        <p className="text-secondary">
                                            <img src={bath} className="propertydetailfirmcontainer4-icon" alt="" /> <span className="text-dark">2</span>
                                        </p>
                                    </div>
                                    &bull;
                                    <div className="col-auto">
                                        <p className="text-secondary">
                                            <span className="text-dark">5,000</span> <span className="text-secondary">ft²</span>
                                        </p>
                                    </div>
                                </div>
                                <hr className="mt-0" />
                                <div className="row w-100 mt-4">
                                    <div className="col-7">
                                        <p className="text-secondary fs-6">Marketed by <u><span className="fw-medium fs-6">John D Wood & Co</span></u></p>
                                    </div>
                                    <div className="col-5">
                                        <img src={brand} className="w-100" alt="" />
                                    </div>
                                </div>
                                <div className="row justify-content-center mt-3 mb-4">
                                    <input type="button" className="propertydetailfirmcontainer4-btn" value="Visit Now" />
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default PropertyDetailFirmContainer4;