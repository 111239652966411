import React from "react";
import './Breadcrumbs.css';

function BreadcrumbsContainer({ breadcrumbs }) {
  return (
    <div className="breadcrumbs-container">
      <div className="container-fluid breadcrumbs-container-inner">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-12 col-sm-12">

            <div className="row">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  {breadcrumbs.map((breadcrumb, index) => (
                    <li key={index} className="breadcrumb-item fs-6">
                      <a href={breadcrumb.href} className="breadcrumbs-a">
                        <span className="breadcrumbs-p-gradient">
                          {breadcrumb.label}
                        </span>
                      </a>
                    </li>
                  ))}
                </ol>
              </nav>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default BreadcrumbsContainer;
