import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "../Components/Header/Header";
import Footer from '../Components/Footer/Footer';
import FirmInfoContainer2 from "../Components/FirmInfoContainer2/FirmInfoContainer2";
import FirmInfoContainer3 from "../Components/FirmInfoContainer3/FirmInfoContainer3";
import FirmInfoContainer4 from "../Components/FirmInfoContainer4/FirmInfoContainer4";

const FirmInfo = () => {
    return (
        <div className="firminfo">
            <div className="container-fluid">

                <div className="row">

                    <div className="col-lg-12">
                        <div className="container-box">
                            < Header />
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="container-box">
                            <FirmInfoContainer2 />
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <FirmInfoContainer3 />
                    </div>

                    <div className="col-lg-12">
                        <FirmInfoContainer4 />
                    </div>

                    <div className="col-lg-12">
                        <div className="container-box">
                            < Footer />
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default FirmInfo;