import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './ListingAgentContainer3Filter.css';

function ListingAgentContainer3Filter() {
    return (
        <div className="listingagentcontainer3filter" id="listingagentcontainer3filter">

            <div className="col-12">
                <div className="card">
                    <div className="card-header card-header-main text-center">
                        Search Filter
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 text-end">
                                <a href="" className="listingagentcontainer3filter-p-gradient fs-5">Reset Filter</a>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="card-body mt-3 mb-0">
                        <h6 className="card-subtitle mb-2 text-body-secondary">Make, Model, & Price</h6>
                        <div className="row">
                            <div className="btn-group dropend no-border">
                                <button type="button" className="btn text-start form-control fw-medium">
                                    Make
                                </button>
                                <button type="button" className="btn dropdown-toggle dropdown-toggle-split text-end" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span className="visually-hidden"> Toggle Dropend</span>
                                    <span className="me-4 text-secondary fw-medium">Any</span>
                                </button>
                                <ul className="dropdown-menu">
                                    <div className="card-header card-header-sub">Select Make</div>
                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col-9">
                                                <input type="search" className="form-control text-secondary" name="" id="" placeholder="Search Make here" />
                                            </div>
                                            <div className="col-3">
                                                <a href="">Reset</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-header fw-medium card-header-sub-2">Selected</div>
                                    <div className="card-body">
                                        <li><span className="fw-medium">Honda</span><span className="text-secondary ms-3">(21)</span></li>

                                    </div>
                                    <div className="card-header fw-medium card-header-sub-2">Most searched for</div>
                                    <div className="card-body">
                                        <li className="mb-2"><span className="fw-medium">Audi</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">BMW</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">Mercedes-Benz</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">Chevrolet</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">Ford</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">Toyota</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                    </div>
                                    <div className="card-header mt-3 fw-medium card-header-sub-2">All makes</div>
                                    <div className="card-body">
                                        <li className="mb-2"><span className="fw-medium">Abarth</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">AC</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">Aixam</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">AK</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">Alfa Romeo</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">List Item</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">List Item</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                    </div>
                                </ul>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="btn-group dropend no-border">
                                <button type="button" className="btn text-start form-control fw-medium">
                                    Model
                                </button>
                                <button type="button" className="btn dropdown-toggle dropdown-toggle-split text-end" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span className="visually-hidden"> Toggle Dropend</span>
                                    <span className="me-4 text-secondary fw-medium">Any</span>
                                </button>
                                <ul className="dropdown-menu">
                                    <div className="card-header card-header-sub">Select Model</div>
                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col-9">
                                                <input type="search" className="form-control text-secondary" name="" id="" placeholder="Search Make here" />
                                            </div>
                                            <div className="col-3">
                                                <a href="">Reset</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-header fw-medium card-header-sub-2">Selected</div>
                                    <div className="card-body">
                                        <li><span className="fw-medium">Jazz</span><span className="text-secondary ms-3">(21)</span></li>

                                    </div>
                                    <div className="card-header mt-3 fw-medium card-header-sub-2">All model</div>
                                    <div className="card-body">
                                        <li className="mb-2"><span className="fw-medium">Accord</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">Civic</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">Concerto</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">CR-V</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">CR-X</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">CR-Z</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">e:Ny1</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">Elyson</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">Fit</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">Freed</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">Add list item</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">Add list item</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                    </div>
                                </ul>
                            </div>
                        </div>
                        <hr />
                        <div className="row mb-4">
                            <div className="btn-group dropend no-border">
                                <button type="button" className="btn text-start form-control fw-medium">
                                    Price
                                </button>
                                <button type="button" className="btn dropdown-toggle dropdown-toggle-split text-end" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span className="visually-hidden"> Toggle Dropend</span>
                                    <span className="me-4 text-secondary fw-medium">Any</span>
                                </button>
                                <ul className="dropdown-menu">
                                    <div className="card-header card-header-sub">Price Range</div>
                                    <div className="card-body">
                                        <div className="row w-100 text-end">
                                            <div>
                                                <a href="">Reset</a>
                                            </div>
                                        </div>
                                        <div className="row align-items-center mt-2">
                                            <div className="col-6">
                                                <input type="text" className="form-control text-secondary" name="" id="" placeholder="Min. Price" />
                                            </div>
                                            <div className="col-6">
                                                <input type="text" className="form-control text-secondary" name="" id="" placeholder="Max. Price" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <li className="mb-2"><span className="fw-medium">Any</span><span className="text-secondary ms-3"></span></li>
                                        <li className="mt-2 mb-2"><span className="fw-medium">£0</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">£500</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">£1000</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">£1500</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">£2000</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">£2500</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">£3000</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">£3500</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">£4000</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">£5000</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">£6000</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">Add list item</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                        <li className="mt-2 mb-2"><span className="fw-medium">Add list item</span><span className="text-secondary ms-3">(21)</span></li>
                                        <hr />
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default ListingAgentContainer3Filter;