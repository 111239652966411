import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './Header.css';
import headerLogo from '../../Assets/Images/Logo.png';

// TEST PUSH

function Header() {

	const [activePage, setActivePage] = useState('');
	const location = useLocation();

	useEffect(() => {
		const path = location.pathname;
		setActivePage(path);
	}, [location]);

	return (
		<header className="header">
			<div className="container-fluid header-container">
				<div className="row justify-content-center">
					<div className="col-10 col-lg-8 mt-2">

						<nav className="navbar navbar-expand-lg">
							<a className="my-2 mb-2" href="/">
								<img src={headerLogo} alt="Logo" className="header-logo" />
							</a>
							<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
								<span className="navbar-toggler-icon"></span>
							</button>
							<div className="collapse navbar-collapse justify-content-between" id="navbarNav">
								<ul className="navbar-nav">

									<li className="header-nav-item header-li">
										<NavLink className={`header-nav-link text-dark ${activePage === '/listing-for-sale' ? 'header-link-active' : ''}`} to="/listing-for-sale">Buy</NavLink>
									</li>

									<li className="header-nav-item">
										<NavLink className={`header-nav-link text-dark ${activePage === '/listing-for-rent' ? 'header-link-active' : ''}`} to='/listing-for-rent'>Rent</NavLink>
									</li>

									<li className="header-nav-item">
										<NavLink className={`header-nav-link text-dark ${activePage === '/listing-new-home' ? 'header-link-active' : ''}`} to='/listing-new-home'>New Home</NavLink>
									</li>

									<li className="header-nav-item">
										<NavLink className={`header-nav-link text-dark ${activePage === '/house-price' ? 'header-link-active' : ''}`} to='/house-price'>House Price</NavLink>
									</li>

									<li className="header-nav-item">
										<NavLink className={`header-nav-link text-dark ${activePage === '/estate-agents' ? 'header-link-active' : ''}`} to='/estate-agents'>Find Estate Agent</NavLink>
									</li>

									<li className="header-nav-item">
										<NavLink className={`header-nav-link text-dark ${activePage === '/commercial' ? 'header-link-active' : ''}`} to='/commercial'>Commercial</NavLink>
									</li>

									<li className="header-nav-item">
										<NavLink className={`header-nav-link text-dark ${activePage === '/overseas' ? 'header-link-active' : ''}`} to='/overseas'>Overseas</NavLink>
									</li>

								</ul>
								<div className="ms-auto d-flex align-items-center headercustommobile-1">
									<div>
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-bookmark no-display mb-1" viewBox="0 0 16 16">
											<linearGradient id="ArrowGradient" x1="0%" y1="0%" x2="100%" y2="0%">
												<stop offset="0%" stopColor="rgb(162, 139, 250)" />
												<stop offset="52.08%" stopColor="rgb(206, 112, 193)" />
												<stop offset="100%" stopColor="rgb(213, 107, 112)" />
											</linearGradient>
											<path fill="url(#ArrowGradient)" d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1z" />
										</svg>
										<a className="header-nav-link text-dark ms-1" href="/property-saved">Saved</a>
									</div>
									<div>
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-person-circle no-display mb-1 ms-3" viewBox="0 0 16 16">
											<path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
											<path fill="url(#ArrowGradient)" fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1" />
										</svg>
										<a className="header-nav-link text-dark ms-1" href="#">Log In</a>
									</div>

								</div>
							</div>
						</nav>

					</div>
				</div>
			</div>
		</header>

	)
}

export default Header;