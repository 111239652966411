import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './FirmInfoContainer4.css';
import firmInfoContainer4Img1 from '../../Assets/Images/FirmInfo/firminfocontainer4-icon-brand.png';
import FirmInfoSellerDetails from "../FirmInfoSellerDetails/FirmInfoSellerDetails";
import FirmInfoSellerInventory from "../FirmInfoSellerInventory/FirmInfoSellerInventory";

function FirmInfoContainer4() {
    return (
        <div className="firminfocontainer4">
            <div className="container-fluid firminfocontainer4-container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-12 col-sm-12">

                        <div className="row w-100 d-flex justify-content-between align-items-center">
                            <div className="col-lg-2 col-md-4 col-sm-6">
                                <img src={firmInfoContainer4Img1} alt="" className="firminfocontainer4-img-1" />
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-6">
                                <input type="button" value="Message Seller" className="firminfocontainer4-btn" />
                            </div>
                        </div>

                        <hr className="mt-4" />

                        <div className="row mt-5">

                            <div className="col-lg-8">
                                <h2>Introduction</h2>
                                <p className="firminfocontainer4-p-1 mt-4">
                                    BINCA is a trade vehicle centre. All vehicles are sold as seen on trade terms
                                    with no warranty or guarantee. BINCA is a trading name of Fehr Brothers LTD;
                                    a Buy It Now Car Auction. As the buyer you are entering a trade sale. All
                                    vehicles are strictly sold as seem and are sold on trade terms. This applies
                                    to every vehicle on sale. We do not guarantee the mileage on any of our vehicles.
                                    We cannot explain or describe vehicles over the phone so please do not call
                                    asking for descriptions of vehicles or service history. We cannot accept
                                    deposits or holding fees. <span className="firminfocontainer4-gradient"><a href="">See more</a></span>
                                </p>

                                <div className="row w-100 d-flex mt-5 justify-content-between align-items-center">
                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                        <h2>Seller Inventory</h2>
                                    </div>
                                    <div className="col-lg-3 col-md-2 col-sm-12 text-end">
                                        <span className="firminfocontainer4-gradient">
                                            <a className="fs-6" href="">See all properties</a>
                                        </span>
                                    </div>
                                </div>

                                <div className="row">
                                    <FirmInfoSellerInventory />
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="row">
                                    < FirmInfoSellerDetails />
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default FirmInfoContainer4;