import React from 'react';
import './ListingCard.css';
import bedIcon from '../../Assets/Images/ListingForSale/listingforsalelist-icon-bed.png';
import bathIcon from '../../Assets/Images/ListingForSale/listingforsalelist-icon-bath.png';
import brandLogo from '../../Assets/Images/ListingForSale/listingforsalelist-icon-brand.png';
import messageIcon from '../../Assets/Images/ListingForSale/listingforsalelist-icon-message.png';
import callIcon from '../../Assets/Images/ListingForSale/listingforsalelist-icon-call.png';
import saveIcon from '../../Assets/Images/ListingForSale/save.png';

function ListingCard({ mainImg, imgList, title, address, price, beds, baths, area, description, addedOn, agent }) {
  return (
    <div className="listing-card">
      <div className="row">
        <div className="col-lg-5 col-md-5 col-sm-12">
          <img src={mainImg} className="listing-card-img" alt="Main listing" />
        </div>
        <div className="col-lg-7 col-md-7 col-sm-12 heightAuto">
          <div className="row justify-content-end mt-2 listingforsalelistcustom-row-mobile">
            <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end align-items-center">
              <img src={saveIcon} className="listing-card-save-icon" alt="Save" />
              <input type="button" className="listing-card-btn fw-medium fs-6" value="Save" />
            </div>
          </div>
          <div className="row mt-3">
            <span className="fw-medium fs-6">{title}</span>
          </div>
          <div className="row justify-content-between mt-3">
            <div className="col-auto">
              <span className="text-secondary fs-6">{address}</span>
            </div>
            <div className="col-auto">
              <span className="text-secondary fs-6">Guide Price</span>
            </div>
          </div>
          <div className="row justify-content-between align-items-center mt-3">
            <div className="col-auto">
              <div className="row">
                <div className="col-auto">
                  <p className="text-secondary">
                    <img src={bedIcon} className="listing-card-icon" alt="Beds" /> <span className="text-dark fs-6">{beds}</span>
                  </p>
                </div>
                &bull;
                <div className="col-auto">
                  <p className="text-secondary">
                    <img src={bathIcon} className="listing-card-icon" alt="Baths" /> <span className="text-dark fs-6">{baths}</span>
                  </p>
                </div>
                &bull;
                <div className="col-auto">
                  <p className="text-secondary">
                    <span className="text-dark fs-6">{area}</span> <span className="text-secondary">ft²</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-auto">
              <span className="fs-3 fw-bold">{price}</span>
            </div>
          </div>
          <div className="row">
            <span className="text-secondary fs-6 listing-card-custom-row-1">
              {description}
            </span>
          </div>
        </div>
      </div>
      <hr className="mt-0" />
      <div className="row">
        <div className="col-lg-5 col-md-5 col-sm-12">
          <div className="row justify-content-between">
            {imgList.map((img, index) => (
              <img key={index} src={img} className="listing-card-small-img" alt={`img-${index}`} />
            ))}
          </div>
        </div>
        <div className="col-lg-7 col-md-7 col-sm-12 heightAuto">
          <div className="row w-100 text-secondary fs-6">
            <div className="col-auto">
              Added on {addedOn} by <span className="fw-medium"><u>{agent}</u></span>
            </div>
          </div>
          <div className="row w-100 fs-6 mt-1 mb-3">
            <div className="col-lg-4 col-md-5 col-sm-12">
              <img src={brandLogo} className="listing-card-brand-logo" alt="Brand" />
            </div>
            <div className="col-auto ps-0 ms-auto">
              <img src={messageIcon} className="listing-card-icon-message me-2" alt="Message" />
              <span className="text-small-custom"><u>Message</u></span>
            </div>
            <div className="col-auto ps-0">
              <img src={callIcon} className="listing-card-icon-call me-2" alt="Call" />
              <span className="text-small-custom"><u>Call</u></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListingCard;
