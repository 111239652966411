import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './PropertyNewsDetailContainer2.css';

function PropertyNewsDetailContainer2() {
    return (
        <div className="propertynewsdetailcontainer2">
            <div className="container-fluid propertynewsdetailcontainer2-container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-sm-12">

                        <div className="row">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item fs-6">
                                        <a href="/" className="propertynewsdetailcontainer2-a">
                                            <span className="propertynewsdetailcontainer2-p-gradient">
                                                Home
                                            </span>
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item fs-6">
                                        <a href="/property-news" className="propertynewsdetailcontainer2-a">
                                            <span className="propertynewsdetailcontainer2-p-gradient">
                                                Property News
                                            </span>
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item active fs-6" aria-current="page">
                                        <span className="propertynewsdetailcontainer2-p-gray">
                                            Lorem ipsum dolor sit amet
                                        </span>
                                    </li>
                                </ol>
                            </nav>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default PropertyNewsDetailContainer2;