import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './PropertyNewsDetailContainer3.css';

function PropertyNewsDetailContainer3() {
    return (
        <div className="propertynewsdetailcontainer3">
            <div className="container-fluid propertynewsdetailcontainer3-container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-12 col-sm-12">

                        <div className="row mt-5">
                            <h1>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            </h1>
                            <p className="fs-4 mt-4">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic recusandae placeat,
                                perspiciatis autem assumenda alias quas consequatur ex culpa magnam non, consequuntur
                                repudiandae quidem laudantium exercitationem et nostrum aspernatur harum!
                            </p>
                        </div>

                        <div className="row mt-3">
                            <p>
                                <span className="fs-5 propertynewsdetailcontainer3-p-gray">By</span>
                                <span className="ms-2 fs-5 text-underline"><u>John Doe</u></span>
                                <span className="ms-2 fs-5 propertynewsdetailcontainer3-p-gray">|</span>
                                <span className="ms-2 fs-5 propertynewsdetailcontainer3-p-gray">November 17, 2023</span>
                            </p>
                        </div>

                        <div className="row mt-3">
                            <div className="col-1 propertynewsdetailcontainer3-svg-margin">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-share" viewBox="0 0 16 16">
                                    <path fill="url(#ArrowGradient)" d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.5 2.5 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5m-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3m11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3" />
                                </svg>
                            </div>
                        </div>

                        <hr className="mt-4"/>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default PropertyNewsDetailContainer3;