import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import ListingForRentContainer2 from "../Components/ListingForRentContainer2/ListingForRentContainer2";
import ListingForRentContainer3 from "../Components/ListingForRentContainer3/ListingForRentContainer3";

const ListingForRent = () => {
    return (
        <div className="listingforrent">
            <div className="container-fluid">

                <div className="row">

                    <div className="col-lg-12">
                        <div className="container-box">
                            <Header />
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="container-box">
                            <ListingForRentContainer2/>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="container-box">
                            <ListingForRentContainer3/>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="container-box">
                            <Footer />
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default ListingForRent;