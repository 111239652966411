import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './HomeContainer5.css';
import homeContainer5img1 from '../../Assets/Images/Home/homecontainer5-img-1.png';
import homeContainer5img2 from '../../Assets/Images/Home/homecontainer5-img-2.png';
import homeContainer5img3 from '../../Assets/Images/Home/homecontainer5-img-3.png';

function HomeContainer5() {
    return (
        <div className="homecontainer5">
            <div className="container-fluid homecontainer5-container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-sm-12">

                        <div className="row">
                            <p className="homecontainer5-p-1">Read our property recent news</p>
                        </div>

                        <div className="row justify-content-between">

                            <div className="col-sm-md-12 col-lg-4 homecontainer5-row2-div">
                                <div className="row">
                                    <img src={homeContainer5img1} className="homecontainer5-img" alt="" />
                                </div>
                                <div className="row w-100">
                                    <p className="homecontainer5-p-2">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    </p>
                                </div>
                                <div className="row">
                                    <p className="homecontainer5-p-3">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                                    </p>
                                </div>
                                <div className="row d-flex w-100 justify-content-between">
                                    <div className="col-6">
                                        <p className="homecontainer5-p-4">
                                            <span className="homecontainer5-gradient">Read more</span>
                                        </p>
                                    </div>
                                    <div className="col-6 text-end">
                                        <p className="homecontainer5-p-5">
                                            <span className="homecontainer5-gray">4 days ago</span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-md-12 col-lg-4 homecontainer5-row2-div">
                                <div className="row">
                                    <img src={homeContainer5img2} className="homecontainer5-img" alt="" />
                                </div>
                                <div className="row w-100">
                                    <p className="homecontainer5-p-2">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    </p>
                                </div>
                                <div className="row">
                                    <p className="homecontainer5-p-3">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                                    </p>
                                </div>
                                <div className="row d-flex w-100 justify-content-between">
                                    <div className="col-6">
                                        <p className="homecontainer5-p-4">
                                            <span className="homecontainer5-gradient">Read more</span>
                                        </p>
                                    </div>
                                    <div className="col-6 text-end">
                                        <p className="homecontainer5-p-5">
                                            <span className="homecontainer5-gray">4 days ago</span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-md-12 col-lg-4 homecontainer5-row2-div">
                                <div className="row">
                                    <img src={homeContainer5img3} className="homecontainer5-img" alt="" />
                                </div>
                                <div className="row w-100">
                                    <p className="homecontainer5-p-2">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    </p>
                                </div>
                                <div className="row">
                                    <p className="homecontainer5-p-3">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                                    </p>
                                </div>
                                <div className="row d-flex w-100 justify-content-between">
                                    <div className="col-6">
                                        <p className="homecontainer5-p-4">
                                            <span className="homecontainer5-gradient">Read more</span>
                                        </p>
                                    </div>
                                    <div className="col-6 text-end">
                                        <p className="homecontainer5-p-5">
                                            <span className="homecontainer5-gray">4 days ago</span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeContainer5;