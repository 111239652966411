import './App.css';
import "bootstrap-icons/font/bootstrap-icons.css";

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Homepage';
import EstateAgents from './Pages/EstateAgents';
import ListingForSale from './Pages/ListingForSale';
import ListingForRent from './Pages/ListingForRent';
import ListingNewHome from './Pages/ListingNewHome';
import HousePrice from './Pages/HousePrice';
import PropertyDetailFirm from './Pages/PropertyDetailFirm';
import PropertyDetailPrivate from './Pages/PropertyDetailPrivate';
import FirmInfo from './Pages/FirmInfo';
import PropertyNews from './Pages/PropertyNews';
import PropertyNewsDetail from './Pages/PropertyNewsDetail';
import PropertySaved from './Pages/PropertySaved';
import ListingAgent from './Pages/ListingAgent';
import ListingPrivate from './Pages/ListingPrivate';
import FAQs from './Pages/FAQs';
import ContactUs from './Pages/ContactUs';
import Advertise from './Pages/Advertise';
import Overseas from './Pages/Overseas';

const routes = [
  { path: "/", component: Home },
  { path: "/listing-for-sale", component: ListingForSale },
  { path: "/listing-for-rent", component: ListingForRent },
  { path: "/listing-new-home", component: ListingNewHome },
  { path: "/house-price", component: HousePrice },
  { path: "/estate-agents", component: EstateAgents },
  { path: "/property-detail-firm", component: PropertyDetailFirm },
  { path: "/property-detail-private", component: PropertyDetailPrivate },
  { path: "/firm-info", component: FirmInfo },
  { path: "/property-news", component: PropertyNews },
  { path: "/property-news-detail", component: PropertyNewsDetail },
  { path: "/property-saved", component: PropertySaved },
  { path: "/listing-agent", component: ListingAgent },
  { path: "/listing-private", component: ListingPrivate },
  { path: "/faqs", component: FAQs },
  { path: "/contact-us", component: ContactUs },
  { path: "/advertise", component: Advertise },
  { path: "/overseas", component: Overseas },

];

const App = () => {
  return (
    <div className="app-container">
      <Router forceRefresh={true}>
        <Routes>
          {routes.map((route, index) => (
            <Route key={index} path={route.path} element={<route.component />} />
          ))}
        </Routes>
      </Router>
    </div>
  );
}

export default App;