import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './FirmInfoContainer3.css';
import firmInfoContainer3Img1 from '../../Assets/Images/FirmInfo/carousel-img-1.webp';
import firmInfoContainer3Img2 from '../../Assets/Images/FirmInfo/carousel-img-2.webp';
import firmInfoContainer3Img3 from '../../Assets/Images/FirmInfo/carousel-img-3.webp';

function FirmInfoContainer3() {
    return (
        <div className="firminfocontainer3">
            <div className="container-fluid firminfocontainer3-container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-12 col-sm-12">

                        <div className="row firminfocontainer3-main-height">
                            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img className="d-block firmcontainer3-main-img" src={firmInfoContainer3Img1} alt="First slide"/>
                                    </div>
                                    <div className="carousel-item">
                                        <img className="d-block firmcontainer3-main-img" src={firmInfoContainer3Img2} alt="Second slide"/>
                                    </div>
                                    <div className="carousel-item">
                                        <img className="d-block firmcontainer3-main-img" src={firmInfoContainer3Img3} alt="Third slide"/>
                                    </div>
                                </div>
                                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="sr-only"></span>
                                </a>
                                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="sr-only"></span>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default FirmInfoContainer3;