import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import PropertyDetailPrivateContainer2 from "../Components/PropertyDetailPrivateContainer2/PropertyDetailPrivateContainer2";
import PropertyDetailPrivateContainer3 from "../Components/PropertyDetailPrivateContainer3/PropertyDetailPrivateContainer3";
import PropertyDetailPrivateContainer4 from "../Components/PropertyDetailPrivateContainer4/PropertyDetailPrivateContainer4";

const PropertyDetailPrivate = () => {
    return (
        <div className="propertydetailprivate">
            <div className="container-fluid">

                <div className="row">

                    <div className="col-lg-12">
                        <div className="container-box">
                            <Header />
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="container-box">
                            <PropertyDetailPrivateContainer2 />
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="container-box">
                            <PropertyDetailPrivateContainer3 />
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="container-box">
                            <PropertyDetailPrivateContainer4/>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="container-box">
                            <Footer />
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default PropertyDetailPrivate;