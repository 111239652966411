import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './HomeContainer3.css';
import homeContainer3Img1 from '../../Assets/Images/Home/homecontainer3-img-1.png';

function HomeContainer3() {
  return (
    <div className="homecontainer3">
      <div className="container-fluid homecontainer3-container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-sm-12">

            <div className="row align-items-center g-0">
              <div className="col-lg-7 col-md-7 col-sm-12">
                <div className="homecontainer3-row-main pt-3 ps-4">
                  <h1 className="display-6">Get a free home valuation</h1>
                  <h5>Find out an estimate for your home's value from an expert.</h5>
                  <input type="button" className="homecontainer3-btn" value="Get a valuation" />
                </div>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-12">
                <img src={homeContainer3Img1} className="w-100 homecontainer3-img" alt="" />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeContainer3;