import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './PropertyNewsDetailContainer4.css';
import propertyNewsDetailContainer4Img1 from '../../Assets/Images/PropertyNewsDetail/propertynewscontainer4-img-1.jpg';
import propertyNewsDetailContainer4Img2 from '../../Assets/Images/PropertyNewsDetail/propertynewscontainer4-img-3.jpg';

function PropertyNewsDetailContainer4() {
    return (
        <div className="propertynewsdetailcontainer4">
            <div className="container-fluid propertynewsdetailcontainer4-container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-12 col-sm-12">

                        <div className="row">
                            <div className="propertynewsdetailcontainer4-relative-div">
                                <div className="propertynewsdetailcontainer4-img-label">
                                    <p>Courtesy Lorem Ipsum</p>
                                </div>
                                <img src={propertyNewsDetailContainer4Img1}
                                    alt="" className="propertynewsdetailcontainer4-img-1" />
                            </div>
                            <p className="fs-5">
                                <span className="propertynewsdetailcontainer4-p-barrier">|</span>
                                <span className="propertynewsdetailcontainer4-p-gray ms-1">Image desc. Lorem Ipsum dolor sit amet.</span>
                            </p>
                        </div>

                        <div className="row">
                            <p className="propertynewsdetailcontainer4-p-1 font-weight-normal">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus maxime quos iste sint
                                voluptatem repudiandae incidunt corrupti repellendus nostrum illum, modi dignissimos
                                molestiae tempore quibusdam perspiciatis? Laudantium aspernatur culpa adipisci aut eos
                                omnis dignissimos quasi nemo, rem sapiente. Asperiores excepturi sint reiciendis fugiat
                                recusandae blanditiis perspiciatis et quae odio consequuntur, ut numquam accusamus.
                                Tempore, alias libero architecto beatae cumque aperiam hic impedit iusto temporibus
                                dicta sit totam itaque quas veniam quasi ipsum fuga veritatis ullam cupiditate nostrum
                                aliquam eum eaque sapiente?
                            </p>
                            <p className="propertynewsdetailcontainer4-p-1 mt-4 font-weight-normal">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus maxime quos iste sint
                                voluptatem repudiandae incidunt corrupti repellendus nostrum illum, modi dignissimos
                                molestiae tempore quibusdam perspiciatis? Laudantium aspernatur culpa adipisci aut eos
                                omnis dignissimos quasi nemo, rem sapiente. Asperiores excepturi sint reiciendis fugiat
                                recusandae blanditiis perspiciatis et quae odio consequuntur, ut numquam accusamus.
                                Tempore, alias libero architecto beatae cumque aperiam hic impedit iusto temporibus
                                dicta sit totam itaque quas veniam quasi ipsum fuga veritatis ullam cupiditate nostrum
                                aliquam eum eaque sapiente?
                            </p>

                            <h3 className="mt-5">Lorem ipsum dolor sit amet.</h3>

                            <p className="propertynewsdetailcontainer4-p-1 mt-4 font-weight-normal">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus maxime quos iste sint
                                voluptatem repudiandae incidunt corrupti repellendus nostrum illum, modi dignissimos
                                molestiae tempore quibusdam perspiciatis? Laudantium aspernatur culpa adipisci aut eos
                                omnis dignissimos quasi nemo, rem sapiente. Asperiores excepturi sint reiciendis fugiat
                                recusandae blanditiis perspiciatis et quae odio consequuntur, ut numquam accusamus.
                                Tempore, alias libero architecto beatae cumque aperiam hic impedit iusto temporibus
                                dicta sit totam itaque quas veniam quasi ipsum fuga veritatis ullam cupiditate nostrum
                                aliquam eum eaque sapiente?
                            </p>
                            <div>
                                <ul>
                                    <li>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Non architecto sed magnam exercitationem nemo et.
                                    </li>
                                    <li>
                                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Possimus nam animi obcaecati ducimus vel aliquam modi voluptates consectetur vero fugiat?
                                    </li>
                                    <li>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid, voluptate! Dolorum repellat dicta blanditiis nobis voluptates tempora illum beatae! Nemo adipisci quas provident nam quo at dolorum ullam, ratione laborum?
                                    </li>
                                </ul>
                            </div>
                            <p className="propertynewsdetailcontainer4-p-1 mt-4 font-weight-normal">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus maxime quos iste sint
                                voluptatem repudiandae incidunt corrupti repellendus nostrum illum, modi dignissimos
                                molestiae tempore quibusdam perspiciatis? Laudantium aspernatur culpa adipisci aut eos
                                omnis dignissimos quasi nemo, rem sapiente. Asperiores excepturi sint reiciendis fugiat
                                recusandae blanditiis perspiciatis et quae odio consequuntur, ut numquam accusamus.
                                Tempore, alias libero architecto beatae cumque aperiam hic impedit iusto temporibus
                                dicta sit totam itaque quas veniam quasi ipsum fuga veritatis ullam cupiditate nostrum
                                aliquam eum eaque sapiente?
                            </p>
                        </div>

                        <div className="row mt-5">
                            <div className="propertynewsdetailcontainer4-relative-div mt-5">
                                <div className="propertynewsdetailcontainer4-img-label">
                                    <p>Courtesy Lorem Ipsum</p>
                                </div>
                                <img src={propertyNewsDetailContainer4Img2}
                                    alt="" className="propertynewsdetailcontainer4-img-1" />
                            </div>
                            <p className="fs-5">
                                <span className="propertynewsdetailcontainer4-p-barrier">|</span>
                                <span className="propertynewsdetailcontainer4-p-gray ms-1">Image desc. Lorem Ipsum dolor sit amet.</span>
                            </p>
                        </div>
                        <div className="row">
                            <h5>
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolorum distinctio aspernatur recusandae laudantium quibusdam tenetur.
                            </h5>
                            <h5 className="mt-4">
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet, eius autem. Maiores ut amet nisi nihil adipisci similique facilis nobis autem asperiores velit, delectus quibusdam sint consequuntur numquam nostrum quod.
                            </h5>
                            <h5 className="mt-4">
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis nisi repudiandae voluptatibus dolorum iste alias dolorem sed tempore eos rem amet, quisquam dolores. Consequatur molestiae laudantium quis voluptates veritatis fugit.
                            </h5>
                        </div>

                        <div className="mt-5 mb-5">
                            <h4>Tags:</h4>
                            <div className="row d-flex">
                                <button type="button" className="propertydetailcontainer4-tags">Lorem</button>
                                <button type="button" className="propertydetailcontainer4-tags">Ipsum</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default PropertyNewsDetailContainer4;