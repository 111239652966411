import React from "react";
import mapIcon from '../../Assets/Images/EstateAgents/estateagentcontainer3-icon-1.png';
import messageIcon from '../../Assets/Images/EstateAgents/estateagentcontainer3-icon-2.png';
import callIcon from '../../Assets/Images/EstateAgents/estateagentcontainer3-icon-3.png';
import './AgentCard.css';

function AgentCard({ agent }) {
  return (
    <div className="col-lg-4 col-sm-12 agentcard-main-div">
      <div className="row ps-2 mt-3">
        <h3>{agent.name}</h3>
      </div>
      <div className="row ps-2 mt-3">
        <div className="col-auto">
          <img src={mapIcon} className="agentcard-icon-map" alt="" />
        </div>
        <div className="col-auto ps-0">
          <span>{agent.address}</span>
        </div>
      </div>
      <div className="row ps-2 mt-3 pe-2 justify-content-between">
        <div className="col-auto">
          <span className="agentcard-gradient">{agent.propertiesForSale} properties for sale</span>
        </div>
        <div className="col-auto">
          <span className="agentcard-gradient">{agent.propertiesForRent} properties for rent</span>
        </div>
      </div>
      <hr />
      <div className="row pe-3 mb-3">
        <div className="col-auto">
          <img src={agent.brandLogo} className="agentcard-brand-logo" alt="" />
        </div>
        <div className="col-auto ms-auto">
          <img src={messageIcon} className="agentcard-icon-message" alt="" />
        </div>
        <div className="col-auto ps-0">
          <span><u><small>Message</small></u></span>
        </div>
        <div className="col-auto">
          <img src={callIcon} className="agentcard-icon-call m-0" alt="" />
        </div>
        <div className="col-auto ps-0">
          <span><u><small>Call</small></u></span>
        </div>
      </div>
    </div>
  );
}

export default AgentCard;
