// ListingForSaleList.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ListingForSaleList.css';
import ListingCard from '../ListingCard/ListingCard';
import mainImg from '../../Assets/Images/ListingForSale/listingforsalelist-img-main.png';
import img1 from '../../Assets/Images/ListingForSale/listingforsalelist-img-1.png';
import img2 from '../../Assets/Images/ListingForSale/listingforsalelist-img-2.png';
import img3 from '../../Assets/Images/ListingForSale/listingforsalelist-img-3.png';

const mockData = [
  {
    mainImg: mainImg,
    imgList: [img1, img2, img3],
    title: "4 bed detached house for sale",
    address: "Knights Road, Oxford OX4",
    price: "£12,300",
    beds: 4,
    baths: 2,
    area: "5,000",
    description: "Borem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad lit...",
    addedOn: "16/10/2023",
    agent: "John D Wood & Co"
  },
  {
    mainImg: mainImg,
    imgList: [img1, img2, img3],
    title: "4 bed detached house for sale 2",
    address: "Knights Road, Oxford OX4 2",
    price: "£12,3002",
    beds: 42,
    baths: 22,
    area: "5,0002",
    description: "2Borem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad lit...",
    addedOn: "16/12/2023",
    agent: "John D 2 Wood & Co"
  },
  // Add more listings if needed
];

function ListingForSaleList() {
  return (
    <div className="listingforsalelist">
      <div className="container-fluid listingforsalelist-container">
        <div className="row">
          {mockData.map((listing, index) => (
            <ListingCard key={index} {...listing} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ListingForSaleList;
