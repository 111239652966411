import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import ListingPrivateContainer2 from "../Components/ListingPrivateContainer2/ListingPrivateContainer2";
import ListingPrivateContainer3 from "../Components/ListingPrivateContainer3/ListingPrivateContainer3";


const ListingPrivate = () => {
    return (
        <div className="listingprivate">
            <div className="container-fluid">

                <div className="row">

                    <div className="col-lg-12">
                        <div className="container-box">
                            <Header />
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="container-box">
                            <ListingPrivateContainer2 />
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="container-box">
                            <ListingPrivateContainer3 />
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="container-box">
                            <Footer />
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default ListingPrivate;