import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './FirmInfoSellerInventory.css';
import propertyDetailContainer4Img1 from '../../Assets/Images/PropertyDetail/propertydetailcontainer4-img-1.png';
import bed from '../../Assets/Images/PropertyDetail/propertydetailcontainer4-icon-bed.png';
import bath from '../../Assets/Images/PropertyDetail/propertydetailcontainer4-icon-bath.png';
import brand from '../../Assets/Images/PropertyDetail/propertydetailcontainer4-icon-brand.png';


function FirmInfoSellerInventory() {
    return (
        <div className="firminfosellerinventory">
            <div className="container-fluid firminfosellerinventory-container">
                <div className="row justify-content-center">
                    <div className="col-12">

                        <div className="row firminfosellerinventory-main-row justify-content-between">

                            <div className="col-lg-4 col-md-6 col-sm-12 firminfosellerinventory-row-div">
                                <div className="row">
                                    <img src={propertyDetailContainer4Img1} className="firminfosellerinventory-img" alt="" />
                                </div>
                                <div className="row w-100">
                                    <p className="text-secondary">4 bed detached house for sale</p>
                                </div>
                                <div className="row w-100">
                                    <h5>£1,084,942</h5>
                                </div>
                                <div className="row w-100">
                                    <h6>Hill View, Sandhills, Oxford OX3</h6>
                                </div>
                                <div className="row text-secondary w-100 justify-content-between">
                                    <div className="col-auto">
                                        <p className="text-secondary">
                                            <img src={bed} className="firminfosellerinventory-icon" alt="" /> <span className="text-dark">4</span>
                                        </p>
                                    </div>
                                    &bull;
                                    <div className="col-auto">
                                        <p className="text-secondary">
                                            <img src={bath} className="firminfosellerinventory-icon" alt="" /> <span className="text-dark">2</span>
                                        </p>
                                    </div>
                                    &bull;
                                    <div className="col-auto">
                                        <p className="text-secondary">
                                            <span className="text-dark">5,000</span> <span className="text-secondary">ft²</span>
                                        </p>
                                    </div>
                                </div>
                                <hr className="mt-0" />
                                <div className="row w-100 mt-4">
                                    <div className="col-7">
                                        <p className="text-secondary fs-6">Marketed by <u><span className="fw-medium fs-6">John D Wood & Co</span></u></p>
                                    </div>
                                    <div className="col-5">
                                        <img src={brand} className="w-100" alt="" />
                                    </div>
                                </div>
                                <div className="row justify-content-center mt-3 mb-4">
                                    <input type="button" className="firminfosellerinventory-btn" value="Visit Now" />
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12 firminfosellerinventory-row-div">
                                <div className="row">
                                    <img src={propertyDetailContainer4Img1} className="firminfosellerinventory-img" alt="" />
                                </div>
                                <div className="row w-100">
                                    <p className="text-secondary">4 bed detached house for sale</p>
                                </div>
                                <div className="row w-100">
                                    <h5>£1,084,942</h5>
                                </div>
                                <div className="row w-100">
                                    <h6>Hill View, Sandhills, Oxford OX3</h6>
                                </div>
                                <div className="row text-secondary w-100 justify-content-between">
                                    <div className="col-auto">
                                        <p className="text-secondary">
                                            <img src={bed} className="firminfosellerinventory-icon" alt="" /> <span className="text-dark">4</span>
                                        </p>
                                    </div>
                                    &bull;
                                    <div className="col-auto">
                                        <p className="text-secondary">
                                            <img src={bath} className="firminfosellerinventory-icon" alt="" /> <span className="text-dark">2</span>
                                        </p>
                                    </div>
                                    &bull;
                                    <div className="col-auto">
                                        <p className="text-secondary">
                                            <span className="text-dark">5,000</span> <span className="text-secondary">ft²</span>
                                        </p>
                                    </div>
                                </div>
                                <hr className="mt-0" />
                                <div className="row w-100 mt-4">
                                    <div className="col-7">
                                        <p className="text-secondary fs-6">Marketed by <u><span className="fw-medium fs-6">John D Wood & Co</span></u></p>
                                    </div>
                                    <div className="col-5">
                                        <img src={brand} className="w-100" alt="" />
                                    </div>
                                </div>
                                <div className="row justify-content-center mt-3 mb-4">
                                    <input type="button" className="firminfosellerinventory-btn" value="Visit Now" />
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12 firminfosellerinventory-row-div">
                                <div className="row">
                                    <img src={propertyDetailContainer4Img1} className="firminfosellerinventory-img" alt="" />
                                </div>
                                <div className="row w-100">
                                    <p className="text-secondary">4 bed detached house for sale</p>
                                </div>
                                <div className="row w-100">
                                    <h5>£1,084,942</h5>
                                </div>
                                <div className="row w-100">
                                    <h6>Hill View, Sandhills, Oxford OX3</h6>
                                </div>
                                <div className="row text-secondary w-100 justify-content-between">
                                    <div className="col-auto">
                                        <p className="text-secondary">
                                            <img src={bed} className="firminfosellerinventory-icon" alt="" /> <span className="text-dark">4</span>
                                        </p>
                                    </div>
                                    &bull;
                                    <div className="col-auto">
                                        <p className="text-secondary">
                                            <img src={bath} className="firminfosellerinventory-icon" alt="" /> <span className="text-dark">2</span>
                                        </p>
                                    </div>
                                    &bull;
                                    <div className="col-auto">
                                        <p className="text-secondary">
                                            <span className="text-dark">5,000</span> <span className="text-secondary">ft²</span>
                                        </p>
                                    </div>
                                </div>
                                <hr className="mt-0" />
                                <div className="row w-100 mt-4">
                                    <div className="col-7">
                                        <p className="text-secondary fs-6">Marketed by <u><span className="fw-medium fs-6">John D Wood & Co</span></u></p>
                                    </div>
                                    <div className="col-5">
                                        <img src={brand} className="w-100" alt="" />
                                    </div>
                                </div>
                                <div className="row justify-content-center mt-3 mb-4">
                                    <input type="button" className="firminfosellerinventory-btn" value="Visit Now" />
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default FirmInfoSellerInventory;