import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './PropertySavedContainer2.css';

function PropertySavedContainer2() {
    const [sortByOption, setSortByOption] = useState('');

    const handleSortByChange = (event) => {
        setSortByOption(event.target.value);
    };

    return (
        <div className="propertysavedcontainer2">
            <div className="container-fluid propertysavedcontainer2-container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-sm-12">
                        <div className="row d-flex justify-content-between align-items-center mt-4">
                            <div className="col-md-5 col-lg-5 col-sm-12 d-flex custommobile1">
                                <h3 className="me-2">4 Properties Saved</h3>
                                <input type="button" value="Delete All" className="btn btn-outline-danger ms-5" />
                            </div>

                            <div className="col-md-5 col-lg-5 col-sm-12 d-flex justify-content-end custommobile2">
                                <label htmlFor="" className="text-secondary mt-1 me-2">Sort by</label>
                                <select value={sortByOption} onChange={handleSortByChange} className="propertysavedcontainer2-dropdown ms-2">
                                    <option value="value1">Most Recent</option>
                                    <option value="value2">Value 2</option>
                                    <option value="value3">Value 3</option>
                                </select>
                            </div>
                        </div>
                        <hr className="mt-4"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PropertySavedContainer2;
