import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import PropertyNewsDetailContainer2 from "../Components/PropertyNewsDetailContainer2/PropertyNewsDetailContainer2";
import PropertyNewsDetailContainer3 from "../Components/PropertyNewsDetailContainer3/PropertyNewsDetailContainer3";
import PropertyNewsDetailContainer4 from "../Components/PropertyNewsDetailContainer4/PropertyNewsDetailContainer4";

const PropertyNewsDetail = () => {
    return (
        <div className="propertynewsdetail">

            <div className="row">

                <div className="col-lg-12">
                    <div className="container-box">
                        < Header />
                    </div>
                </div>

                <div className="col-lg-12">
                    <div className="container-box">
                        <PropertyNewsDetailContainer2 />
                    </div>
                </div>

                <div className="col-lg-12">
                    <div className="container-box">
                        <PropertyNewsDetailContainer3 />
                    </div>
                </div>

                <div className="col-lg-12">
                    <div className="container-box">
                        <PropertyNewsDetailContainer4 />
                    </div>
                </div>

                <div className="col-lg-12">
                    <div className="container-box">
                        <Footer />
                    </div>
                </div>

            </div>

        </div>
    )
}

export default PropertyNewsDetail;