import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './HomeContainer4.css';
import homeContainer4Img1 from '../../Assets/Images/Home/homecontainer4-img-1.png';
import bed from '../../Assets/Images/Home/homecontainer4-icon-bed.png';
import bath from '../../Assets/Images/Home/homecontainer4-icon-bath.png';
import brand from '../../Assets/Images/Home/homecontainer4-icon-brand.png';
import navLeftArrow from '../../Assets/Icons/navleftarrow.png';
import navRightArrow from '../../Assets/Icons/navrightarrow.png';

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <img src={navLeftArrow}
      className="nav-icons position-absolute top-50 start-0 translate-middle cursor-pointer"
      onClick={onClick}
    />
  );
}

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <img src={navRightArrow}
      className="nav-icons position-absolute top-50 start-100 translate-middle cursor-pointer"
      onClick={onClick}
    />
  );
}

function CustomSlide(props) {
  const { imgSrc, title, price, address, beds, baths, size, agent } = props;
  return (
    <div className="homecontainer4-row-div">
      <div className="row">
        <img src={imgSrc} className="homecontainer4-img" alt={title} />
        <div className="homecontainer4-img-label">
          <div className="row">
            <div className="col-auto">
              <i className="bi bi-image-fill text-white"></i><span className="text-white ms-1">11</span>
              <i className="bi bi-camera-video text-white ms-2"></i><span className="text-white ms-1">1</span>
            </div>
          </div>
        </div>
      </div>
      <div className="row w-100 ps-2">
        <p className="text-secondary">{title}</p>
      </div>
      <div className="row w-100 ps-2">
        <h5>{price}</h5>
      </div>
      <div className="row w-100 ps-2">
        <h6>{address}</h6>
      </div>
      <div className="row text-secondary px-2">
        <div className="col-2">
          <p className="text-secondary d-flex">
            <img src={bed} className="homecontainer4-icon" alt="Beds" /> <span className="text-dark fw-medium ms-2">{beds}</span>
          </p>
        </div>
        <div className="col-1">
          <i className="bi bi-dot m-0 p-0"></i>
        </div>
        <div className="col-2">
          <p className="text-secondary d-flex">
            <img src={bath} className="homecontainer4-icon" alt="Baths" /><span className="text-dark fw-medium ms-2">{baths}</span>
          </p>
        </div>
        <div className="col-1">
          <i className="bi bi-dot"></i>
        </div>
        <div className="col-auto">
          <p className="text-secondary">
            <span className="text-dark fw-medium">{size}</span> <span className="text-secondary">ft²</span>
          </p>
        </div>
      </div>
      <hr className="mt-0" />
      <div className="row w-100 mt-4 ps-2">
        <div className="col-7">
          <p className="text-secondary fs-6">Marketed by <u><span className="fw-medium fs-6">{agent}</span></u></p>
        </div>
        <div className="col-5">
          <img src={brand} className="w-100" alt="Agent" />
        </div>
      </div>
      <div className="row justify-content-center mt-3 mb-4">
        <input type="button" className="homecontainer4-btn" value="Visit Now" />
      </div>
    </div>
  );
}

function HomeContainer4() {

  const [selectedOption2, setSelectedOption2] = useState("For-Sale-2");

  const handleOptionChange2 = (event) => {
    setSelectedOption2(event.target.value);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const propertiesGlasgow = [
    {
      imgSrc: homeContainer4Img1,
      title: "4 bed detached house for sale",
      price: "£1,084,942",
      address: "Hill View, Sandhills, Oxford OX3",
      beds: 4,
      baths: 2,
      size: "5,000",
      agent: "John D Wood & Co"
    },
    {
      imgSrc: homeContainer4Img1,
      title: "4 bed detached house for sale",
      price: "£1,084,942",
      address: "Hill View, Sandhills, Oxford OX3",
      beds: 4,
      baths: 2,
      size: "5,000",
      agent: "John D Wood & Co"
    },
    {
      imgSrc: homeContainer4Img1,
      title: "4 bed detached house for sale",
      price: "£1,084,942",
      address: "Hill View, Sandhills, Oxford OX3",
      beds: 4,
      baths: 2,
      size: "5,000",
      agent: "John D Wood & Co"
    },
    {
      imgSrc: homeContainer4Img1,
      title: "4 bed detached house for sale",
      price: "£1,084,942",
      address: "Hill View, Sandhills, Oxford OX3",
      beds: 4,
      baths: 2,
      size: "5,000",
      agent: "John D Wood & Co"
    },
    // Add other properties here
  ];

  const propertiesBirmingham = [
    {
      imgSrc: homeContainer4Img1,
      title: "4 bed detached house for sale",
      price: "£1,084,942",
      address: "Hill View, Sandhills, Oxford OX3",
      beds: 4,
      baths: 2,
      size: "5,000",
      agent: "John D Wood & Co"
    },
    {
      imgSrc: homeContainer4Img1,
      title: "4 bed detached house for sale",
      price: "£1,084,942",
      address: "Hill View, Sandhills, Oxford OX3",
      beds: 4,
      baths: 2,
      size: "5,000",
      agent: "John D Wood & Co"
    },
    {
      imgSrc: homeContainer4Img1,
      title: "4 bed detached house for sale",
      price: "£1,084,942",
      address: "Hill View, Sandhills, Oxford OX3",
      beds: 4,
      baths: 2,
      size: "5,000",
      agent: "John D Wood & Co"
    },
    {
      imgSrc: homeContainer4Img1,
      title: "4 bed detached house for sale",
      price: "£1,084,942",
      address: "Hill View, Sandhills, Oxford OX3",
      beds: 4,
      baths: 2,
      size: "5,000",
      agent: "John D Wood & Co"
    },
    // Add other properties here
  ];

  const propertiesSheffield = [
    {
      imgSrc: homeContainer4Img1,
      title: "4 bed detached house for sale",
      price: "£1,084,942",
      address: "Hill View, Sandhills, Oxford OX3",
      beds: 4,
      baths: 2,
      size: "5,000",
      agent: "John D Wood & Co"
    },
    {
      imgSrc: homeContainer4Img1,
      title: "4 bed detached house for sale",
      price: "£1,084,942",
      address: "Hill View, Sandhills, Oxford OX3",
      beds: 4,
      baths: 2,
      size: "5,000",
      agent: "John D Wood & Co"
    },
    {
      imgSrc: homeContainer4Img1,
      title: "4 bed detached house for sale",
      price: "£1,084,942",
      address: "Hill View, Sandhills, Oxford OX3",
      beds: 4,
      baths: 2,
      size: "5,000",
      agent: "John D Wood & Co"
    },
    {
      imgSrc: homeContainer4Img1,
      title: "4 bed detached house for sale",
      price: "£1,084,942",
      address: "Hill View, Sandhills, Oxford OX3",
      beds: 4,
      baths: 2,
      size: "5,000",
      agent: "John D Wood & Co"
    },
    // Add other properties here
  ];

  return (
    <div className="homecontainer4">
      <div className="container-fluid homecontainer4-container">
        <div className="row justify-content-center">
          <div className="col-11 col-lg-8">

            <div className="row align-items-center">
              <div className="col-auto">
                <h2>Popular Locations</h2>
              </div>
              <div className="col-auto homecontainer4-btn-container">
                <input
                  type="radio"
                  className="btn-check"
                  id="for-sale-btn-2"
                  autoComplete="off"
                  value="For-Sale-2"
                  checked={selectedOption2 === 'For-Sale-2'}
                  onChange={handleOptionChange2}
                />
                <label
                  className={`btn btn-dark homecontainer4-for-sale-btn text-dark fw-medium ${selectedOption2 === 'For-Sale-2' ? 'selected' : ''
                    }`}
                  htmlFor="for-sale-btn-2"
                >
                  For Sale
                </label>

                <input
                  type="radio"
                  className="btn-check"
                  id="for-rent-btn-2"
                  autoComplete="off"
                  value="For-Rent-2"
                  checked={selectedOption2 === 'For-Rent-2 '}
                  onChange={handleOptionChange2}
                />
                <label
                  className={`btn btn-dark homecontainer4-for-rent-btn text-dark fw-medium ${selectedOption2 === 'For-Rent-2' ? 'selected' : ''
                    }`}
                  htmlFor="for-rent-btn-2"
                >
                  Rent
                </label>
              </div>
              <div className="col-auto ms-auto">
                <a className="homecontainer4-gradient fs-6" href="">Explore more cities</a>
              </div>
            </div>

            <div className="row mt-5">
              <h4><span className="homecontainer4-h4">Properties for sale in Glasgow</span> <span className="homecontainer4-gradient fs-5 fw-normal ms-3">See all</span></h4>
            </div>
            <div className="row homecontainer4-main-row mt-3">
              <div className="slider-container">
                <Slider {...settings} className="position-relative">
                  {propertiesGlasgow.map((property, index) => (
                    <CustomSlide
                      key={index}
                      imgSrc={property.imgSrc}
                      title={property.title}
                      price={property.price}
                      address={property.address}
                      beds={property.beds}
                      baths={property.baths}
                      size={property.size}
                      agent={property.agent}
                    />
                  ))}
                </Slider>
              </div>
            </div>


            <div className="row mt-5">
              <h4><span className="homecontainer4-h4">Properties for sale in Birmingham</span> <span className="homecontainer4-gradient fs-5 fw-normal ms-3">See all</span></h4>
            </div>
            <div className="row homecontainer4-main-row justify-content-between mt-3">
              <div className="slider-container">
                <Slider {...settings} className="position-relative">
                  {propertiesBirmingham.map((property, index) => (
                    <CustomSlide
                      key={index}
                      imgSrc={property.imgSrc}
                      title={property.title}
                      price={property.price}
                      address={property.address}
                      beds={property.beds}
                      baths={property.baths}
                      size={property.size}
                      agent={property.agent}
                    />
                  ))}
                </Slider>
              </div>
            </div>


            <div className="row mt-5">
              <h4><span className="homecontainer4-h4">Properties for sale in Sheffield</span> <span className="homecontainer4-gradient fs-5 fw-normal ms-3">See all</span></h4>
            </div>
            <div className="row homecontainer4-main-row justify-content-between mt-3">
              <div className="slider-container">
                <Slider {...settings} className="position-relative">
                  {propertiesSheffield.map((property, index) => (
                    <CustomSlide
                      key={index}
                      imgSrc={property.imgSrc}
                      title={property.title}
                      price={property.price}
                      address={property.address}
                      beds={property.beds}
                      baths={property.baths}
                      size={property.size}
                      agent={property.agent}
                    />
                  ))}
                </Slider>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeContainer4;