import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";

const HousePrice = () => {
    return (
        <div className="houseprice">
            <div className="container-fluid">

                <div className="row">

                    <div className="col-lg-12">
                        <div className="container-box">
                            <Header />
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="container-box">
                            <h1 className="display-1 text-center">
                                FUTURE CONTENT HERE!
                            </h1>
                            <h1 className="display-1 text-center">
                                FUTURE CONTENT HERE!
                            </h1>
                            <h1 className="display-1 text-center">
                                FUTURE CONTENT HERE!
                            </h1>
                            <h1 className="display-1 text-center">
                                FUTURE CONTENT HERE!
                            </h1>
                            <h1 className="display-1 text-center">
                                FUTURE CONTENT HERE!
                            </h1>
                            <h1 className="display-1 text-center">
                                FUTURE CONTENT HERE!
                            </h1>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="container-box">
                            <Footer />
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default HousePrice;