import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './ListingForSaleContainer3.css'
import ListingForSaleList from "../ListingForSaleList/ListingForSaleList";

function ListingForSaleContainer3() {
  return (
    <div className="listingforsalecontainer3">
      <div className="container-fluid listingforsalecontainer3-container">
        <div className="row justify-content-center">
          <div className="col-11 col-lg-9">

            <div className="row">

              <div className="col-lg-9">
                <div className="row">
                  <h5 className="text-secondary fw-normal">Properties For Sale in Oxford</h5>
                </div>
                <div className="row mb-3 align-items-center">
                  <div className="col-auto">
                    <h4>312 results</h4>
                  </div>
                  <div className="col-auto ms-auto">
                    <span className="text-secondary fs-5">Sort by</span>
                  </div>
                  <div className="col-auto">
                    <div className="dropdown">
                      <button className="btn btn-outline-dark dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                        Publish Date
                      </button>
                      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <li><a className="dropdown-item" href="#">Action</a></li>
                        <li><a className="dropdown-item" href="#">Another action</a></li>
                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-auto">
                    <input className="btn btn-outline-dark" type="button" value="Map View" />
                  </div>
                </div>
                <hr className="mb-4 listingforsalecontainer3-hr" />

                <ListingForSaleList />

              </div>

              <div className="col-lg-3">
                <div className="row ms-2 listingforsalecontainer3-sidebar">
                  <div className="listingforsalecontainer3-ad-container align-items-center">
                    <h1 className="display-1 text-center">ADS</h1>
                  </div>
                  <div className="row mt-5 fw-medium">
                    <span className="w-medium listingforsalecontainer3-gradient">See Properties to buy in Oxford</span>
                  </div>
                  <div className="row mt-3">
                    <span className="fw-medium">Properties Nearby Oxford</span>
                    <span className="mt-4 fw-medium listingforsalecontainer3-gradient">Kidlington</span>
                    <span className="mt-4 fw-medium listingforsalecontainer3-gradient">Sunningwell</span>
                    <span className="mt-4 fw-medium listingforsalecontainer3-gradient">Garsington</span>
                    <span className="mt-4 fw-medium listingforsalecontainer3-gradient">Radley</span>
                    <span className="mt-4 fw-medium listingforsalecontainer3-gradient">Wootton</span>
                    <span className="mt-4 fw-medium listingforsalecontainer3-gradient">Farmoor</span>
                    <span className="mt-4 fw-medium listingforsalecontainer3-gradient">Stanton St. John</span>
                  </div>
                  <div className="row mt-5">
                    <span className="fw-medium">Properties within Oxford</span>
                    <span className="mt-4 fw-medium listingforsalecontainer3-gradient">Headington</span>
                    <span className="mt-4 fw-medium listingforsalecontainer3-gradient">Summertown</span>
                    <span className="mt-4 fw-medium listingforsalecontainer3-gradient">Wheatley</span>
                    <span className="mt-4 fw-medium listingforsalecontainer3-gradient">Botley</span>
                    <span className="mt-4 fw-medium listingforsalecontainer3-gradient">Cowley</span>
                    <span className="mt-4 fw-medium listingforsalecontainer3-gradient">Jericho</span>
                    <span className="mt-4 fw-medium listingforsalecontainer3-gradient">Chalgrove</span>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default ListingForSaleContainer3;