// import React from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Header from "../Components/Header/Header";
// import Footer from "../Components/Footer/Footer";
// import FaqAccordion from "../Components/FAQs/FAQs";

// const FAQs = () => {
//     return (
//         <div className="faqs">
//             <div className="container-fluid">

//                 <div className="row">

//                     <div className="col-lg-12">
//                         <div className="container-box">
//                             <Header />
//                         </div>
//                     </div>

//                     <div className="col-lg-12">
//                         <div className="container-box">
//                             <FaqAccordion />
//                         </div>
//                     </div>

//                     <div className="col-lg-12">
//                         <div className="container-box">
//                             <Footer />
//                         </div>
//                     </div>

//                 </div>

//             </div>
//         </div>
//     )
// }

// export default FAQs;

import React from "react";
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";

const FAQS = () => {
  const navigate = useNavigate();

  const contactUsLink = () => {
    navigate("/contact-us");
    window.scrollTo(0, 0);
  };
  const accordionItems = [
    {
      id: "faq-1",
      question: "How does the free trial work?",
      answer:
        "Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      id: "faq-2",
      question: "How do you find different criteria in your process?",
      answer:
        "Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      id: "faq-3",
      question: "What do you look for in a founding team?",
      answer:
        "Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      id: "faq-4",
      question: "Do you recommend Pay as you go or Pre pay?",
      answer:
        "Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      id: "faq-5",
      question: "What do I get for $0 with my plan?",
      answer:
        "Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      id: "faq-6",
      question: "If I already have a website, can I transfer it to another?",
      answer:
        "Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      id: "faq-7",
      question: "How can I accept credit cards online?",
      answer:
        "Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      id: "faq-8",
      question: "What can I use to build my website?",
      answer:
        "Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
  ];

  return (
    <div className="container-fluid faqsaccordion-container">
      <Header />
      <div className="row justify-content-center">
        <div className="col-11 col-lg-8">

          <div className="row mt-5 text-center">
            <span className="fs-5 faqs-gradient mt-5"><i>&#8212; &nbsp; FAQ</i></span>
          </div>

          <div className="row mt-3 text-center">
            <h1 className="display-2 fw-medium">Questions & Answers</h1>
          </div>

          <div className="row mt-5">
            <div className="accordion accordion-style-four" id="accordionOne">
              {accordionItems.map((item, index) => (
                <div className="accordion-item" key={item.id}>
                  <div className="accordion-header" id={`heading-${index}`}>
                    <button
                      className={`accordion-button ${index === 2 ? "" : "collapsed"}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse-${index}`}
                      aria-expanded={index === 2 ? "true" : "false"}
                      aria-controls={`collapse-${index}`}
                    >
                      <span>{`0${index + 1}.`}</span> {item.question}
                    </button>
                  </div>
                  <div
                    id={`collapse-${index}`}
                    className={`accordion-collapse collapse ${index === 2 ? "show" : ""
                      }`}
                    aria-labelledby={`heading-${index}`}
                    data-bs-parent="#accordionOne"
                  >
                    <div className="accordion-body">
                      <p className="text-white">{item.answer}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="row mt-5 justify-content-center text-center">
            <h1 className="display-6 mt-4 fw-medium">Didn't get your answer?</h1>
            <input type="submit" className="btn fw-medium faqs-button fs-5 mt-4" value="Contact Us" onClick={contactUsLink} />
          </div>

        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default FAQS;
