import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './ListingForSaleContainer2.css';

function ListingForSaleContainer2() {
  return (
    <div className="listingforsalecontainer2">
      <div className="container-fluid listingforsalecontainer2-container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-12 col-sm-12">

            <div className="row mb-3 mt-3">
              <div className="col-lg-4">
                <div className="row w-100">
                  <span className="fw-medium text-white mb-3">Enter Location</span>
                </div>
                <div className="input-group mb-3">
                  <input type="text" className="form-control" aria-label="Text input with dropdown button" />
                  <button className="btn btn-light dropdown-toggle fw-medium listingforsalecontainer2-height-1" type="button" data-bs-toggle="dropdown" aria-expanded="false">This Area Only</button>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li><a className="dropdown-item" href="#">Action</a></li>
                    <li><a className="dropdown-item" href="#">Another action</a></li>
                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-3">
                <div className="row w-100">
                  <span className="fw-medium text-white mb-3">Bedroom</span>
                </div>
                <div className="dropdown">
                  <button className="btn btn-light dropdown-toggle listingforsalecontainer2-dropdown fw-medium" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                    Any Beds
                  </button>
                  <ul className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton">
                    <li><a className="dropdown-item" href="#">Action</a></li>
                    <li><a className="dropdown-item" href="#">Another action</a></li>
                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-3">
                <div className="row w-100">
                  <span className="fw-medium text-white mb-3">Price Range</span>
                </div>
                <div className="dropdown">
                  <button className="btn btn-light dropdown-toggle listingforsalecontainer2-dropdown fw-medium" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                    Any Price
                  </button>
                  <ul className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton">
                    <li><a className="dropdown-item" href="#">Action</a></li>
                    <li><a className="dropdown-item" href="#">Another action</a></li>
                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-3">
                <div className="row w-100">
                  <span className="fw-medium text-white mb-3">Property Types</span>
                </div>
                <div className="dropdown">
                  <button className="btn btn-light dropdown-toggle listingforsalecontainer2-dropdown fw-medium" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                    Any Types
                  </button>
                  <ul className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton">
                    <li><a className="dropdown-item" href="#">Action</a></li>
                    <li><a className="dropdown-item" href="#">Another action</a></li>
                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-3">
                <div className="row w-100">
                  <span className="fw-medium text-white mb-3">Select Filter</span>
                </div>
                <div className="dropdown">
                  <button className="btn btn-light dropdown-toggle listingforsalecontainer2-dropdown fw-medium" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                    Filter
                  </button>
                  <ul className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton">
                    <li><a className="dropdown-item" href="#">Action</a></li>
                    <li><a className="dropdown-item" href="#">Another action</a></li>
                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  )
}

export default ListingForSaleContainer2;