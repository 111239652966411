import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import PropertyDetailFirmContainer2 from "../Components/PropertyDetailFirmContainer2/PropertyDetailFirmContainer2";
import PropertyDetailFirmContainer3 from "../Components/PropertyDetailFirmContainer3/PropertyDetailFirmContainer3";
import PropertyDetailFirmContainer4 from "../Components/PropertyDetailFirmContainer4/PropertyDetailFirmContainer4";

const PropertyDetailFirm = () => {
    return (
        <div className="propertydetailfirm">
            <div className="container-fluid">

                <div className="row">

                    <div className="col-lg-12">
                        <div className="container-box">
                            <Header />
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="container-box">
                            <PropertyDetailFirmContainer2 />
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="container-box">
                            <PropertyDetailFirmContainer3 />
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="container-box">
                            <PropertyDetailFirmContainer4/>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="container-box">
                            <Footer />
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default PropertyDetailFirm;