import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import HomeContainer3 from "../Components/HomeContainer3/HomeContainer3";
import HomeContainer5 from "../Components/HomeContainer5/HomeContainer5";
import HomeContainer4 from "../Components/HomeContainer4/HomeContainer4";
import HomeContainer2 from "../Components/HomeContainer2/HomeContainer2";

const Home = () => {
    return (
        <div className="home">
            <div className="container-fluid">

                <div className="row">

                    <div className="col-lg-12">
                        <div className="container-box homecontainer-1">
                            < Header />
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="container-box homecontainer-2">
                            < HomeContainer2 />
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="container-box homecontainer-3">
                            < HomeContainer3 />
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="container-box homecontainer-4">
                            < HomeContainer4 />
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="container-box homecontainer-5">
                            < HomeContainer5 />
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="container-box homecontainer-6">
                            < Footer />
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default Home;